import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import ShippingView from '../views/ShippingView.vue';
import MonitoringView from '../views/MonitoringView.vue';
import PaymentView from '../views/PaymentView.vue';
import ReviewView from '../views/ReviewView.vue';
import CompleteView from '../views/CompleteView.vue';
import FinancingCompleteView from '../views/FinancingCompleteView.vue';
import NotFoundView from '../views/NotFoundView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:orderId/:authToken',
    component: HomeView,
    children: [{
      path: '',
      name: 'Checkout',
      redirect: { name: 'Shipping' },
    }, {
      path: 'shipping',
      name: 'Shipping',
      component: ShippingView,
    }, {
      path: 'monitoring',
      name: 'Monitoring',
      component: MonitoringView,
    }, {
      path: 'payment',
      name: 'Payment',
      component: PaymentView,
    }, {
      path: 'review',
      name: 'Review',
      component: ReviewView,
      meta: {
        hideSidebar: true,
      },
    }, {
      path: 'complete',
      name: 'Complete',
      component: CompleteView,
      meta: {
        hideNavbar: true,
        hideSidebar: true,
      },
    }, {
      path: 'financing-complete',
      name: 'FinancingComplete',
      component: FinancingCompleteView,
      meta: {
        hideNavbar: false,
        hideSidebar: true,
      },
    }],
  },
  {
    path: '*',
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/checkout',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, _, next) => {
  if($(".modal-backdrop").length > 0) {
    $(".modal-backdrop").remove()
    $(".modal-open").removeClass("modal-open")
  }

  const editCallbackUrl = to.query?.edit_callback_url as string;
  if (editCallbackUrl) {
    sessionStorage.setItem('editCallbackUrl', editCallbackUrl);
    next(to.path);
  }
  next();
});

export default router;
