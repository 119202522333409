






import { Vue, Component, Prop } from 'vue-property-decorator';

type AlertType = 'warning' | 'error';

@Component
export default class Alert extends Vue {
  @Prop() readonly type!: AlertType;

  @Prop() readonly message!: string;

  get styles() {
    return this.type === 'warning'
      ? 'alert alert-warning text-warning'
      : 'alert alert-danger text-danger';
  }
}
