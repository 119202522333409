const ForCurrency = ($event: any) => {
  const { value } = $event.target;

  if (value) {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which;

    // only allow number and one dot
    if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') !== -1)) {
      // 46 is dot
      $event.preventDefault();
    }
  }
};

export default ForCurrency;
