import { OrderAddress, OrderAddressKind } from '@/interfaces';

const newAddress = (kind: OrderAddressKind = 'ship'): OrderAddress => ({
  firstName: '',
  lastName: '',
  stateName: '',
  city: '',
  address1: '',
  address2: '',
  zipCode: '',
  phone: '',
  alternativePhone: '',
  kind,
});

export default newAddress;
