



















































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapMutations, mapGetters, mapState } from 'vuex';

// APIs
import CheckoutApi from '@/apis/checkout-api';

// Views
import NotFoundView from './NotFoundView.vue';
import ServerErrorView from './ServerErrorView.vue';

// Components
import IconStripe from '../components/IconStripe.vue';
import Navbar from '../components/Navbar.vue';
import ProgressBar from '../components/ProgressBar.vue';
import CartSummary from '../components/CartSummary.vue';
import SubNav from '../components/SubNav.vue';

// Interfaces
import { OrderStep, Order } from '../interfaces';

// Utils
import { monitorBeforePaymentSteps, nonMonitoredSteps, nonShippingSteps } from '../utils/checkout-steps';

@Component({
  components: {
    IconStripe,
    NotFoundView,
    ServerErrorView,
    Navbar,
    ProgressBar,
    CartSummary,
    SubNav,
  },
  methods: {
    ...mapMutations(['updateCurrentOrder', 'updateSteps', 'unlockUntilStep']),
  },
  computed: {
    ...mapState([
      'currentOrder',
      'isCustomerCheckingOut',
    ]),
    ...mapGetters([
      'orderHasService',
      'lastUnlockedStep',
      'isFinancingInProgress',
      'isMasPrepopulated',
    ]),
  },
})
export default class HomeView extends Vue {
  loading = true;

  duplicateMonitoringFlagEnabled = true;

  orderNotFound = false;

  serverError = false;

  error = false;

  checkoutApi = new CheckoutApi();

  updateCurrentOrder!: Function;

  updateSteps!: Function;

  orderHasService!: boolean;

  unlockUntilStep!: Function;

  currentOrder!: Order;

  isCustomerCheckingOut!: boolean;

  isFinancingInProgress!: boolean;

  isMasPrepopulated! : boolean;

  async mounted() {
    try {
      const { orderId, authToken } = this.$route.params;
      const currentOrder = await this.checkoutApi.fetchOrder(orderId, authToken);
      const { step } = currentOrder;
      this.updateCurrentOrder(currentOrder);
      if (currentOrder.checkoutAs === 'fieldSalesPartner'){
        this.updateSteps(nonShippingSteps)
      }
      else{
        this.updateSteps(this.orderHasService && !this.isMasPrepopulated ? monitorBeforePaymentSteps : nonMonitoredSteps);
      }
      this.loading = false;
      

      if (!step) {
        return;
      }

      this.unlockUntilStep(step);
      this.redirectToStep(step);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        this.orderNotFound = true;
      } else {
        this.serverError = true;
        console.error(ex);
      }

      this.error = true;
      this.loading = false;
    }
  }

  get routeHasNavbar() {
    // @ts-ignore
    return !this.$route.meta.hideNavbar;
  }

  get routeHasSidebar() {
    // @ts-ignore
    return !this.$route.meta.hideSidebar;
  }

  redirectToStep(step: string) {
    const routeName = (this.$route.name ?? OrderStep.SHIPPING).toLowerCase();
    const stepName = step.toLowerCase();

    if (routeName === 'financingcomplete') {
      if (!this.currentOrder.isFinanced) {
        this.$router.push('/not_found');
      }
      return;
    }

    if (stepName === 'review' && this.isFinancingInProgress && routeName !== stepName) {
      this.$router.push('payment');
      return;
    }

    if (stepName === 'complete' && !this.isCustomerCheckingOut && this.currentOrder.postSaleManagerUrl && !this.currentOrder.isEligibleForPayCheck) {
      window.location.href = this.currentOrder.postSaleManagerUrl;
    } else if (routeName !== stepName) this.$router.push(step);
  }
}
