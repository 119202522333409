



































// Vue
import { Vue, Prop, Component } from 'vue-property-decorator';

// Interfaces
import { LocationSearchResult } from '../interfaces/location-search-result';

@Component
export default class LocationPicker extends Vue {
  @Prop({ default: [] }) readonly locations!: LocationSearchResult[]
}

