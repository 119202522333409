





































































































































































// Vue
import { Vue, Prop, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// Interfaces
import { OrderAddress, StepName, OrderStep, ValidateLocation } from '../../interfaces';

// Utils
import { addressNeedsCorrection } from '../../utils/address-validations';

@Component({
  computed: {
    ...mapGetters(['nextStepPath', 'isMasPrepopulated']),
    ...mapState(['isCustomerCheckingOut']),
  },
  methods: {
    addressNeedsCorrection,
  },
})
export default class ValidateLocationModal extends Vue {
  @Prop() readonly validation!: ValidateLocation;

  @Prop() address!: OrderAddress;

  @Prop() currentPath!: StepName;

  @Prop() onNext!: Function;

  addressNeedsCorrection!: Function;

  isCustomerCheckingOut!: boolean;

  isMasPrepopulated!: boolean;

  selectedAddress = this.validation.cleanStreetAddress || '';

  updateAddress() {
    if (this.currentPath === OrderStep.SHIPPING) {
      this.address.cleanStreetAddress = this.selectedAddress;
    }
    this.address.address1 = this.selectedAddress;
  }

  next() {
    if (addressNeedsCorrection(this.address, this.validation.cleanStreetAddress)) {
      this.updateAddress();
    }
    this.onNext();
  }

  get dispatchSiteValidation() {
    return !this.validation.isMonitored && this.isMasPrepopulated;
  }

  get isAlreadyServed() {
    return this.validation.isMonitored && this.currentPath === OrderStep.MONITORING;
  }
}
