









































































































// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';

// Components
import TextField from './TextField.vue';
import SelectBox from './SelectBox.vue';
import LocationPicker from '../LocationPicker.vue';

// Interfaces
import { LocationSearchResult, OrderAddress } from '../../interfaces';

// Data
import StateOptions from '../../constants/state-options';

// APIs
import CheckoutApi from '../../apis/checkout-api';

@Component({
  components: {
    TextField,
    SelectBox,
    LocationPicker,
  },
})
export default class AddressForm extends Vue {
  checkoutApi = new CheckoutApi();

  locationData: LocationSearchResult[] = [];

  isAwaitingLocationData = false;

  isZipCodeValid = true;

  @Prop({ default: {} }) readonly address!: OrderAddress;

  @Prop({ default: false }) readonly disabled!: boolean;

  @Prop({ default: false }) readonly withNames!: boolean;

  @Prop(Function) readonly onUpdate!: Function;

  get stateOptions() {
    return StateOptions.map((state) => ({
      id: state.abbr,
      name: state.name,
    }));
  }

  async mounted() {
    if (!this.address.county && this.address.zipCode) {
      await this.searchForLocationsByZipCode();
    }
  }

  async updateZipCode() {
    await this.searchForLocationsByZipCode();
  }

  async searchForLocationsByZipCode() {
    const zipCode = this.address.zipCode.trim();
    this.isZipCodeValid = true;
    if (zipCode.length === 5) {
      this.isAwaitingLocationData = true;
      try {
        this.locationData = await this.checkoutApi.addressesByZipCode(
          zipCode,
        );

        if (this.locationData.length === 1) {
          this.locationSelected(this.locationData[0]);
        } else if (this.locationData.length > 1) {
          ($((this.$refs.locationPicker as Vue).$el) as any).modal(
            'show',
          );
        } else if (this.locationData.length === 0) {
          this.isZipCodeValid = false;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isAwaitingLocationData = false;
      }

    }
  }

  locationSelected(location: LocationSearchResult) {
    this.address.city = location.city;
    this.address.stateName = location.state;
    this.address.county = location.county;

    this.closeLocationPicker();
  }

  closeLocationPicker(): void {
    ($((this.$refs.locationPicker as Vue).$el) as any).modal('hide');
  }
}
