import {
  OrderAddress, OrderFinancingStatus, OrderItem, State, Step, StepName,
} from '@/interfaces';

export default {
  currentStep: (state: State, _: any, rootState: any): Step | undefined => {
    if (!rootState.route) {
      return undefined;
    }
    const currentStepPath = rootState.route.path.split('/').pop() as StepName;
    return state.steps.find(step => step.path === currentStepPath);
  },

  nextStepPath: (state: State, _: any, rootState: any): string => {
    const currentStepPath = rootState.route.path.split('/').pop() as StepName;
    for (let i = 1; i < state.steps.length; i += 1) {
      const previousStep = state.steps[i - 1];
      if (previousStep.path === currentStepPath) {
        return state.steps[i].path;
      }
    }
    return currentStepPath;
  },

  lastUnlockedStep: (state: State): Step => {
    const unlockedSteps = state.steps.filter(step => step.unlocked);
    return unlockedSteps[unlockedSteps.length - 1];
  },

  isFeatureFlagActive: (state: State): Function => (
    (name: string): boolean => (state.currentOrder?.featureFlags ?? []).some(flag => flag === name)
  ),

  services: (state: State): OrderItem[] => [
    ...(state.currentOrder?.package?.services ?? []),
    ...(state.currentOrder?.serviceAddons ?? []),
  ],

  itemAddons: (state: State): OrderItem[] => [
    ...(state.currentOrder?.itemAddons ?? []),
  ],

  servicesTotal: (_: State, getters: any): number => (
    getters.services.reduce((total: number, s: OrderItem) => total + s.price * s.quantity, 0) - getters.warrantyAmount
  ),

  todayTotal: (state: State): number => {
    const isTotalZero = state.currentOrder?.isFinanced || state.currentOrder?.isSubsidized || state.currentOrder?.isPayCheck || state.currentOrder?.isSunnovaFinanced;
    return isTotalZero ? 0 : (state.currentOrder?.total || 0);
  },

  warrantyAmount: (_: State, getters: any): number => (
    getters.services?.find((s: OrderItem) => s.name === 'Brinks Home Plus Plan' || s.netsuiteId === 235144)?.price ?? 0
  ),

  allInMonthlyPrice: (state: State, getters: any): number => (
    getters.servicesTotal + state.currentOrder?.financingMonthlyTotal
  ),

  shippingAddress: (state: State): OrderAddress => state.currentOrder?.addresses?.find(a => a.kind === 'ship')!,
  monitoringAddress: (state: State): OrderAddress => state.currentOrder?.addresses?.find(a => a.kind === 'monitor')!,
  billingAddress: (state: State): OrderAddress => state.currentOrder?.addresses?.find(a => a.kind === 'bill')!,

  isMasPrepopulated: (state: State): boolean => !!state.currentOrder?.customer.ismasprepopulated,
  isSplitPaymentAllowed: (state: State): boolean => !!state.currentOrder?.isEligibleForSplitPayment &&
  !state.currentOrder?.isFinanced &&
  !state.currentOrder.isSunnovaFinanced &&
  !state.currentOrder?.isSubsidized,

  orderHasSystem: (state: State): boolean => !!state.currentOrder?.package,
  orderHasService: (_: State, getters: any): boolean => getters.services.length > 0,
  orderHasItemAddons: (_: State, getters: any): boolean => getters.itemAddons.length > 0,
  orderCanBePlaced: (state: State, getters: any): boolean => (
    !!state.currentOrder?.netsuiteQuoteId && !getters.isFinancingInProgress
  ),
  orderRequiresMonitoring: (_: State, getters: any): boolean => getters.orderHasService && !getters.isMasPrepopulated,
  isFinancingInProgress: (state: State): boolean => (
    !!state.currentOrder &&
    state.currentOrder.isFinanced &&
    !!state.currentOrder.financingStatus &&
    ![OrderFinancingStatus.ACTIVE, OrderFinancingStatus.DECLINED].includes(state.currentOrder.financingStatus)
  ),
  monitoringOnlyOrder: (state: State, getters: any): boolean => !state.currentOrder?.package && getters.orderHasService && !getters.orderHasItemAddons,
  isDIYOrder: (state: State): boolean => state.currentOrder?.store === "ecomm_diy",
  hasDIYInstall: (state: State, getters: any): boolean => !!(getters.itemAddons as OrderItem[]).find(i => {
    console.log(i.name.toLowerCase())
    return i.name.toLowerCase() === "diy install" }),
  hasValidCustomerAuthorizationAnswers: (state: State): boolean | undefined => {
    const authorizationInformation = state.currentOrder?.authorizationInformation;
    return !!authorizationInformation?.isUnderstandCancelationPolicy && !!authorizationInformation.isUnderstandSignAgreement;
  },
};
