





























































































































































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { Order, OrderStep } from '../interfaces';

// Components
import AnimatedNumber from './AnimatedNumber.vue';
import Tooltip from './Tooltip.vue';
import Icon from './Icon.vue';
import SelectBox from './form/SelectBox.vue';
import TextField from './form/TextField.vue';

// Data
import PayCheckOptions from '../constants/paycheck-options';

@Component({
  components: {
    AnimatedNumber,
    Tooltip,
    Icon,
    SelectBox,
    TextField,
  },
  computed: {
    ...mapState(['currentOrder', 'isCustomerCheckingOut']),
    ...mapGetters(['orderRequiresMonitoring', 'isFinancingInProgress']),
  },
  methods: {
    ...mapMutations(['updateCurrentOrder', 'blockNextSteps']),
  },
})

export default class PaymentOptions extends Vue {
  currentOrder!: Order;
  updateCurrentOrder!: Function;
  blockNextSteps!: Function;
  orderRequiresMonitoring!: boolean;
  isCustomerCheckingOut!: boolean;
  isFinancingInProgress!: boolean

  get payCheckDeduction(): string
  {
    return this.currentOrder.monitoringInformation?.payChecks
      ? `$${(this.currentOrder.total / this.currentOrder.monitoringInformation?.payChecks).toFixed(2)}`
      : "$0";
  }

  get payCheckOptions() {
    return PayCheckOptions.map((paycheck: any) => ({
      id: paycheck.id,
      name: paycheck.name,
    }));
  }

  financeOrder() {
    const newAttributes: Partial<Order> = {
      ...this.paymentAttributes,
      isFinanced: true,
      additionalCreditCard: null,
      step: OrderStep.PAYMENT,
    }

    if(this.currentOrder.creditCard && this.currentOrder.additionalCreditCard) {
      newAttributes.creditCard = {
        ...this.currentOrder.creditCard,
        useForMonitoring: this.orderRequiresMonitoring,
        authorizedAmount: this.currentOrder.total,
      }
    }

    this.updateCurrentOrder({
      ...newAttributes,
    });
    this.blockNextSteps(OrderStep.PAYMENT)
  }

  financeOrderWithSunnova() {
    this.updateCurrentOrder({
      ...this.paymentAttributes,
      isSunnovaFinanced: true,
      step: OrderStep.PAYMENT,
    });
    this.blockNextSteps(OrderStep.PAYMENT)
  }

  subsidizeOrder() {
    this.updateCurrentOrder({
      ...this.paymentAttributes,
      isSubsidized: true,
      step: OrderStep.PAYMENT,
    });
    this.blockNextSteps(OrderStep.PAYMENT)
  }

  payTodayOrder() {
    this.updateCurrentOrder({
      ...this.paymentAttributes,
      step: OrderStep.PAYMENT,
    });
    this.blockNextSteps(OrderStep.PAYMENT)
  }

  paychecksOrder() {
    this.updateCurrentOrder({
      ...this.paymentAttributes,
      isPayCheck: true,
      step: OrderStep.PAYMENT,
    });
    this.blockNextSteps(OrderStep.PAYMENT)
  }

  get paymentAttributes() {
    return {
      isSplitPayment: false,
      isFinanced: false,
      isSubsidized: false,
      isSunnovaFinanced: false,
      isPayCheck: false,
    }
  }

  get financingStatusText() {
    return {
      Approved: "Application Approved",
      RequestOnboarding: "Application email sent",
      Opened: "Application Opened",
      Declined: "Application Declined",
      Active: "Application Confirmed",
    }[this.currentOrder.financingStatus ?? "RequestOnboarding"]
  }
}
