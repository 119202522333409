import {
  UPDATE_CURRENT_ORDER,
  UPDATE_STEPS,
  BLOCK_NEXT_STEPS,
  UNLOCK_NEXT_STEP,
  UNLOCK_UNTIL_STEP,
  ORDER_SUBMITTING,
  ORDER_SUBMITTING_COMPLETE,
  UPDATE_IS_ORDER_ALREADY_PROCESSED,
  UPDATE_CONTRACT_RECIPIENT,
  UPDATE_ERROR_MESSAGE,
} from './mutation-types';

import { Order, State, Step, StepName, ContactRecipient } from '../interfaces';

export default {
  [UPDATE_CURRENT_ORDER](state: State, order: Order) {
    state.currentOrder = { ...state.currentOrder, ...order };
    state.isSalesRepCheckingOut = state.currentOrder.checkoutAs === 'salesRep';
    state.isAuthorizedPartnerCheckingOut = state.currentOrder.checkoutAs === 'authorizedPartner';
    state.isFieldSalesPartnerCheckingOut = state.currentOrder.checkoutAs === 'fieldSalesPartner';
    state.isCustomerCheckingOut = state.currentOrder.checkoutAs === 'customer';
  },
  [UPDATE_STEPS](state: State, steps: Array<Step>) {
    state.steps = steps;
  },
  [BLOCK_NEXT_STEPS](state: State, currentStep: StepName) {
    const currentStepIndex = state.steps.findIndex(step => step.path === currentStep);
    state.steps = state.steps.map((step, index) =>
      index >= currentStepIndex + 1 ? { ...step, unlocked: false } : step,
    );
  },
  [UNLOCK_NEXT_STEP](state: State, currentStep: StepName) {
    const currentStepIndex = state.steps.findIndex(step => step.path === currentStep);
    state.steps = state.steps.map((step, index) =>
      index <= currentStepIndex + 1 ? { ...step, unlocked: true } : step,
    );
  },
  [UNLOCK_UNTIL_STEP](state: State, currentStep: StepName) {
    const currentStepIndex = state.steps.findIndex(step => step.path === currentStep);
    state.steps = state.steps.map((step, index) =>
      index <= currentStepIndex ? { ...step, unlocked: true } : step,
    );
  },
  [ORDER_SUBMITTING](state: State) {
    state.isSubmitting = true;
    state.errorMessage = null;
  },
  [ORDER_SUBMITTING_COMPLETE](state: State, errorMessage?: string) {
    state.isSubmitting = false;
    if(errorMessage) {
      state.errorMessage = errorMessage
    }
  },
  [UPDATE_ERROR_MESSAGE](state: State, errorMessage?: string) {
    state.errorMessage = errorMessage;
  },
  [UPDATE_IS_ORDER_ALREADY_PROCESSED](state: State, isOrderProcessed: boolean) {
    state.isOrderAlreadyProcessed = isOrderProcessed;
  },
  [UPDATE_CONTRACT_RECIPIENT](state: State, contractRecipient: ContactRecipient) {
    state.contractRecipientEmail = contractRecipient.email;
    state.contractRecipientPhone = contractRecipient.phone;
    state.acceptedSmsTerms = contractRecipient.acceptedSmsTerms;
  },
};
