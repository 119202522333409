










































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TextField extends Vue {
  @Prop(String) readonly label!: string

  @Prop(String) readonly labelSupplement!: string

  @Prop(String) readonly value!: string

  @Prop({ default: 'text' }) readonly type!: string

  @Prop({ default: 'off' }) readonly autocorrect!: string

  @Prop({ default: 'on' }) readonly autocomplete!: string

  @Prop({ default: '.*\\w+.*' }) readonly pattern!: string

  // Pipe-separated string of desired validation rules
  @Prop({ default: '' }) readonly validationRules!: string

  @Prop({ default: 'eager' }) readonly validationMode!: string

  @Prop(Boolean) readonly required!: boolean

  updateValue(value: string) {
    this.$emit('input', value);
  }

  get name() {
    return this.$attrs.name || this.label;
  }

  get validators(): string {
    if (!this.required) {
      return this.validationRules;
    }

    const splitValidators = (this.validationRules && this.validationRules.split('|')) || [];
    if (!splitValidators.includes('required')) {
      splitValidators.unshift('required'); // frontload
    }

    return splitValidators.join('|');
  }
}
