




























// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// Interfaces
import { Order, OrderAddress } from '../interfaces';

import AddressCard from './AddressCard.vue'

@Component({
  components: {
    AddressCard,
  },
  computed: {
    ...mapState(['currentOrder']),
    ...mapGetters(['shippingAddress', 'isDIYOrder', 'hasDIYInstall']),
  },
})
export default class ReviewOrderChecklist extends Vue {
  currentOrder!: Order;
  shippingAddress!: OrderAddress;
  hasDIYInstall!: boolean;
  isDIYOrder!: boolean;
}
