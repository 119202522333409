















// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

// Interfaces
import { Order } from '../interfaces';

// Utils
import { mixpanelTrackStep } from '../utils/mixpanel';

@Component({
  computed: {
    ...mapState(['currentOrder']),
  },
})
export default class CompleteView extends Vue {
  currentOrder!: Order;

  mounted() {
    mixpanelTrackStep('FinancingComplete', this.currentOrder);
  }

  get orderNumber() {
    return this.currentOrder.netsuiteQuoteId ?? this.currentOrder.id;
  }

}
