






































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { CustomerAuthorizationInformation, Order } from '../../interfaces';

// Components 
import Alert from '../Alert.vue'; 
import SelectBox from '../form/SelectBox.vue';

@Component({
  components: {
    Alert, 
    SelectBox,
  },
  computed: {
    ...mapState(['currentOrder']),
    ...mapGetters(['hasValidCustomerAuthorizationAnswers']),
  },
    
})
export default class CustomerAuthorization extends Vue {
  currentOrder!: Order;
  hasValidCustomerAuthorizationAnswers!: Function;

  get questionnaireAnswerOptions() {
    return [
      { name: 'Yes', id: 'true' },
      { name: 'No', id: '' }, // empty string evaluates to false in js
    ]
  }

  invalidAnswerMessage() {
    const authorizationInformation = this.currentOrder.authorizationInformation!;
    return `Please revisit ${this.invalidAnswers(authorizationInformation)} with the customer as the order can not be placed with the current
      ${this.invalidAnswersCount(authorizationInformation) === 1 ? 'answer' : 'answers'}. If you need assistance, please call 
      <a href="tel:9722322236">(972) 232-2236</a>.`
  }

  invalidAnswers(authorizationInformation: CustomerAuthorizationInformation) {
    if (!authorizationInformation?.isUnderstandCancelationPolicy && !authorizationInformation.isUnderstandSignAgreement) {
      return 'questions #1 and #2'
    } if (!authorizationInformation.isUnderstandCancelationPolicy) {
      return 'question #1'
    }
    return 'question #2'
  }

  invalidAnswersCount(authorizationInformation: CustomerAuthorizationInformation) {
    return !authorizationInformation?.isUnderstandCancelationPolicy && !authorizationInformation?.isUnderstandSignAgreement ? 2 : 1. 
  }
}
