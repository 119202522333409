






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {
  @Prop(String) readonly title!: string;

  @Prop(String) readonly placement!: 'left' | 'right' | 'top' | 'bottom';

  mounted() {
    if (process.env.NODE_ENV !== 'test') {
      // @ts-ignore
      ($(this.$refs.tooltip) as any).tooltip();
    }
  }
}
