





































































































































// Vue
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import TextField from '../form/TextField.vue';
import Checkbox from '../form/Checkbox.vue';
import ReviewOrderChecklist from '../ReviewOrderChecklist.vue';

// Interfaces
import { Order, OrderAddress, ContactRecipient } from '../../interfaces';

@Component({
  components: {
    TextField,
    Checkbox,
    ValidationObserver,
    ReviewOrderChecklist,
  },
  computed: {
    ...mapState(['currentOrder', 'isCustomerCheckingOut', 'isSubmitting']),
    ...mapGetters(['shippingAddress', 'isDIYOrder']),
  },
  methods: {
    ...mapMutations([
      'updateContractRecipients',
      'unlockNextStep',
    ]),
  },
})
export default class ConfirmContactRecModal extends Vue {

  @Emit()
  updateSignNow(val: boolean) {
    return val;
  }

  @Prop()
  dismissModal!: Function;

  @Prop()
  submit!: Function;

  currentOrder!: Order;
  email = '';
  phone: string | undefined = '';
  updateContractRecipients!: Function;
  agreeToTerms = false;
  shippingAddress!: OrderAddress;
  shouldSignNow = true;
  isSubmitting!: boolean;
  isCustomerCheckingOut!: boolean
  isDIYOrder!: boolean

  @Watch('shouldSignNow')
  shouldSignNowChanged(val: any) {
    this.updateSignNow(val);
  }

  get agreeToTermsRequired() {
    return !this.currentOrder.isFinanced;
  }

  get btnText() {
    return this.shouldSignNow ? 'Sign & Place Order' : 'Place Order';
  }

  get phoneSms() {
    const addressData = this.shippingAddress;
    return addressData.phone;
  }

  get emailContract() {
    return this.currentOrder?.customer.email;
  }

  get disclaimer() {
    return this.currentOrder.isFinanced
      ? 'Email listed here will receive the contract for signing.<br />Please verify the item below and make changes as necessary.'
      : 'Email and phone number listed here will receive the contract for signing.<br />Please verify each item below and make changes as necessary.';
  }

  get phoneValidations() {
    return `phone|secondaryPhone:${
      this.currentOrder.monitoringInformation!.primaryPhone
    }`;
  }

  get isSecondaryContactRequired() {
    return !this.isCustomerCheckingOut || this.isDIYOrder
  }

  submitForm() {
    this.dismissModal();
    const data: ContactRecipient = {
      email: this.email || '',
      phone: this.phone || '',
      acceptedSmsTerms: this.agreeToTerms,
    };
    this.updateContractRecipients(data);
    this.submit();
  }

  mounted() {
    this.phone = this.phoneSms;
    this.email = this.emailContract || '';
  }
}
