



























































// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';

// APIs
import CheckoutApi from '../../apis/checkout-api';

// Components
import TextField from './TextField.vue';
import Tooltip from '../Tooltip.vue';
import Icon from '../Icon.vue';

// Interfaces
import {
  CodewordEncryptRequest,
  CodewordEncryptResponse,
  CodewordResponse,
  CodewordUpdateRequest,
  CustomerInformation,
  isValidCodewordRequest,
  Order,
} from '../../interfaces';

@Component({
  components: {
    TextField,
    Tooltip,
    Icon,
  },
  computed: {
    ...mapState([
      'currentOrder',
      'isSubmitting',
    ]),
  },
  methods: {
    ...mapMutations([
      'orderSubmitting',
      'orderSubmittingComplete',
    ]),
  },
})

export default class Codeword extends Vue {
  checkoutApi = new CheckoutApi();
  currentOrder!: Order;
  encryptedCodeword = "";
  encryptedCodewordConfirmation = "";
  orderSubmitting!: Function;
  orderSubmittingComplete!: Function;
  isValid = false;

  @Prop(String) readonly updateCodewordInMAS!: string
  async checkCodewordValues() {
    const inputData: isValidCodewordRequest = {
      data: this.encryptedCodeword,
    };

    if (this.encryptedCodeword === '' || this.encryptedCodewordConfirmation === '') {
      return;
    }

    try {
      this.orderSubmitting();
      this.$emit('status', 'Checking code word...')

      if (this.encryptedCodeword !== this.encryptedCodewordConfirmation) {
        this.encryptedCodewordConfirmation = '';
        (document.querySelector('.form-control.is-invalid') as HTMLInputElement).focus();
        this.orderSubmittingComplete();
        this.$emit('status', undefined);
        return;
      }

      this.isValid = await this.checkoutApi.isValidCodeword(inputData);
      if (this.isValid) {
        const payload : CodewordEncryptRequest = { data: this.encryptedCodeword };
        const response : CodewordResponse = await this.checkoutApi.encryptCodeword(payload);
        const actualToken: CodewordEncryptResponse = response.data;
        this.currentOrder.customer.codeword = actualToken.Token;
        if(this.updateCodewordInMAS === "true"){
          await this.updateCodeword(this.currentOrder.customer)
        }
        this.orderSubmittingComplete();
        this.$emit('status', undefined);
      }
      else {
        this.resetCodeword(this.isValid);
      }
    } catch(e) {
      this.resetCodeword(this.isValid);
    }
  }

  get codewordConfirmation() {
    return `codeword|codewordConfirmation:${this.encryptedCodeword}`;
  }

  private resetCodeword(isvalid: boolean){
    if (!isvalid && this.encryptedCodeword === this.encryptedCodewordConfirmation) {
      this.encryptedCodewordConfirmation = '';
    } else {
      this.encryptedCodewordConfirmation = 'notMatch';
    }

    this.encryptedCodeword = '^invalid ';
    this.currentOrder.customer.codeword = '';
    this.orderSubmittingComplete();
    this.$emit('status', undefined);
  }

  private async updateCodeword(customerInfo: CustomerInformation){
    let result = true;
    if (customerInfo.ismasprepopulated && customerInfo?.codeword && customerInfo?.prospectNumber) {
      const payload: CodewordUpdateRequest = {
        prospectNumber: customerInfo?.prospectNumber,
        token: customerInfo?.codeword,
      };
      const data: CodewordResponse = await this.checkoutApi.updateCodeword(payload);

      if (data && !data.success) {
        result = false;
      }
    }
    return result;
  }
}
