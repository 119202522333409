




import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';

// @ts-ignore
import { tween } from 'shifty';

@Component({
  filters: {
    properNumber(value: number | string, decimals: number) {
      if (value === null || Number.isNaN(Number(value))) { return ''; }
      return Number(value).toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
    },
  },
})
export default class AnimatedNumber extends Vue {
  @Prop({ default: 0, type: [Number, String] }) readonly value!: number | string

  @Prop({ default: 2 }) readonly decimals!: number

  displayValue = this.value

  get showDefault() {
    const num = Number(this.value);
    return Number.isNaN(num) || num === 0;
  }

  @Watch('value')
  async onValueChanged(newValue: string | number, oldValue: string | number) {
    const newVal = Number(newValue);
    const oldVal = Number(oldValue);

    if (newVal !== oldVal) {
      tween({
        from: { val: oldVal },
        to: { val: newVal },
        duration: 200,
        step: (state: any) => { this.displayValue = state.val; },
      });
    }
  }
}

