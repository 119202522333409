const UPDATE_CURRENT_ORDER = 'updateCurrentOrder';
const UPDATE_STEPS = 'updateSteps';
const BLOCK_NEXT_STEPS = 'blockNextSteps';
const UNLOCK_NEXT_STEP = 'unlockNextStep';
const UNLOCK_UNTIL_STEP = 'unlockUntilStep';
const ORDER_SUBMITTING = 'orderSubmitting';
const ORDER_SUBMITTING_COMPLETE = 'orderSubmittingComplete';
const UPDATE_IS_ORDER_ALREADY_PROCESSED = 'updateIsOrderAlreadyProcessed';
const UPDATE_CONTRACT_RECIPIENT = 'updateContractRecipients';
const UPDATE_ERROR_MESSAGE = 'updateErrorMessage';

export {
  UPDATE_CURRENT_ORDER,
  UPDATE_STEPS,
  BLOCK_NEXT_STEPS,
  UNLOCK_NEXT_STEP,
  UNLOCK_UNTIL_STEP,
  ORDER_SUBMITTING,
  ORDER_SUBMITTING_COMPLETE,
  UPDATE_IS_ORDER_ALREADY_PROCESSED,
  UPDATE_CONTRACT_RECIPIENT,
  UPDATE_ERROR_MESSAGE,
};
