






import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import config from './config';
import './assets/scss/index.scss';

if (config.sentryDsn) {
  Sentry.init({
    environment: config.currentEnvironment,
    dsn: config.sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export default {};
