




















































































































































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

// APIs
import CheckoutApi from '../apis/checkout-api';
import {payloadStepCompletedMonitoring} from '../apis/payloads/checkout-api'

// Components
import AddressForm from '../components/form/AddressForm.vue';
import Codeword from '../components/form/Codeword.vue';
import TextField from '../components/form/TextField.vue';
import Checkbox from '../components/form/Checkbox.vue';
import DateField from '../components/form/DateField.vue';
import SectionCard from '../components/SectionCard.vue';
import { ValidateLocationModal } from '../components/modals';
import StepActions from '../components/StepActions.vue';

// Interfaces
import { Order, OrderAddress, OrderStep } from '../interfaces';
import { ValidateLocation } from '../interfaces/validate-location';

// Constants
import newAddress from '../constants/new-address';

// Mixins
import OrderValidationMixin from '../mixins/orderValidationMixin';

// Utils
import { isValidAddress } from '../utils/address-validations';
import { isValidActivationDate, notBeforeAfterEightOClock } from '../utils/date-validations';
import { mixpanelTrackStep } from '../utils/mixpanel';
import { pushGAFunnel } from '../utils/gtm';

@Component({
  mixins: [OrderValidationMixin],
  components: {
    AddressForm,
    Codeword,
    TextField,
    SectionCard,
    Checkbox,
    ValidateLocationModal,
    ValidationObserver,
    StepActions,
    DateField,
  },
  computed: {
    ...mapGetters(['nextStepPath', 'monitoringAddress', 'shippingAddress', 'isMasPrepopulated', 'isDIYOrder']),
    ...mapState(['currentOrder', 'isCustomerCheckingOut', 'isSubmitting', 'isFieldSalesPartnerCheckingOut']),
  },
  methods: {
    isValidActivationDate, notBeforeAfterEightOClock,
    ...mapMutations([
      'updateCurrentOrder',
      'unlockNextStep',
      'orderSubmitting',
      'orderSubmittingComplete',
    ]),
  },
})
export default class MonitoringView extends Vue {
  checkoutApi = new CheckoutApi();
  monitoringAddress!: OrderAddress;
  shippingAddress!: OrderAddress;
  isSubmitting!: boolean;
  isFieldSalesPartnerCheckingOut!: boolean;
  isCustomerCheckingOut!: boolean;
  currentOrder!: Order;
  emailLabel = "Email Address";
  orderSubmitting!: Function;
  orderSubmittingComplete!: Function;
  updateCurrentOrder!: Function;
  unlockNextStep!: Function;
  nextStepPath!: string;
  isValidAddress = true;
  addressValidations!: ValidateLocation;
  isMasPrepopulated!: boolean;
  isDIYOrder!: boolean
  isButtonSpinning = true;
  continueButtonText = '';

  mounted() {
    if (!this.currentOrder.monitoringInformation!.primaryPhone) {
      this.currentOrder.monitoringInformation!.primaryPhone = this.shippingAddress.phone!;
    }
    this.orderSubmittingComplete();
    pushGAFunnel(3, this.currentOrder)
    mixpanelTrackStep('Monitoring', this.currentOrder)

    if (this.isFieldSalesPartnerCheckingOut) {
      this.useNewAddress();
    }
  }

  get phoneValidations() {
    return `phone|secondaryPhone:${
      this.currentOrder.monitoringInformation!.primaryPhone
    }`;
  }

  useShippingAddress() {
    this.updateCurrentOrder({
      ...this.currentOrder,
      addresses: this.currentOrder.addresses?.filter(
        (a: OrderAddress) => a.kind !== 'monitor',
      ),
    });
  }

  useNewAddress() {
    this.updateCurrentOrder({
      ...this.currentOrder,
      addresses: [
        ...(this.currentOrder.addresses ?? []),
        newAddress('monitor'),
      ],
    });
  }

  get isFormDisabled(){
    return !!(this.isMasPrepopulated || this.isSubmitting);
  }

  async validateAddress() {
    // Setting the address1 of the shipping address back to the original address entered,
    // so that the same address is being used during address validation check on the Monitoring page,
    // to maintain consistency between address validation check on shipping and monitoring page.
    if (this.shippingAddress.originalAddress1) this.shippingAddress.address1 = this.shippingAddress.originalAddress1;
    const address = this.monitoringAddress || this.shippingAddress;
    this.addressValidations = await this.checkoutApi.validateAddress(address);
    this.isValidAddress = !!isValidAddress({
      validations: this.addressValidations,
      address: address!,
      isCustomerCheckingOut: this.isCustomerCheckingOut,
      useShippingAddress: !this.monitoringAddress,
      step: OrderStep.MONITORING,
      isMasPrepopulated: this.isMasPrepopulated,
    });
  }

  async submit() {
    this.updateCurrentOrder(this.currentOrder);
    this.orderSubmitting();
    await this.validateAddress();

    if (this.isValidAddress) {
      this.stepCompleted();
    } else {
      this.orderSubmittingComplete(false);
      ($((this.$refs.validateLocationModal as Vue).$el) as any).modal('show');
    }
  }

  stepCompleted() {
    // Updating the shipping address1 back with the cleanStreeAddress received during the address validation call on the
    // Shipping page, so that clean address is being sent downstream
    if (this.shippingAddress.cleanStreetAddress) this.shippingAddress.address1 = this.shippingAddress.cleanStreetAddress;
    this.isValidAddress = true;
    this.updateCurrentOrder(this.currentOrder);
    const payload = payloadStepCompletedMonitoring(this.currentOrder)
    this.checkoutApi.completeStep(this.currentOrder, OrderStep.MONITORING, payload).then(response => {
      if (response.netsuiteQuoteId) {
        this.currentOrder.netsuiteQuoteId = response.netsuiteQuoteId;
        this.currentOrder.addresses = response.addresses;
        this.updateCurrentOrder(this.currentOrder);
      }
    });
    this.unlockNextStep(OrderStep.MONITORING);
    this.$router.push(this.nextStepPath);
  }
}
