






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
  @Prop(String) readonly name!: string;

  @Prop(String) readonly classOptions!: string;

  @Prop(String) readonly size!: string;
}
