






























// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// @ts-ignore
import { CollapseExtensions } from 'shared-assets';

// Components
import MobileCartSummary from './MobileCartSummary.vue';
import Price from './Price.vue';

// Interfaces
import { Order } from '../interfaces';

@Component({
  components: {
    MobileCartSummary,
    Price,
  },
  computed: {
    ...mapState([
      'currentOrder',
    ]),
    ...mapGetters([
      'orderHasSystem',
      'todayTotal',
    ]),
  },
})
export default class SubNav extends Vue {
  duplicateMonitoringFlagEnabledForPayment = false

  @Prop({ default: 'true' }) readonly duplicateMonitoringFlagEnabled!: boolean

  @Prop({ default: 'd-lg-none' }) readonly responsiveClass!: string

  orderHasSystem!: Function

  currentOrder!: Order

  isExpanded = false

  collapseId = 'subnav-collapse'

  mounted() {
    // Supports clicking outside collapse to minimize it
    CollapseExtensions.init();
  }

  toggleClicked() {
    this.isExpanded = !this.isExpanded;
  }
}
