






































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

// Interfaces
import { Step } from '@/interfaces';

// Components
import Icon from './Icon.vue';

@Component({
  components: {
    Icon,
  },
  computed: {
    ...mapState([
      'steps',
    ]),

    ...mapGetters([
      'currentStep', 'isMasPrepopulated',
    ]),
  },
})
export default class ProgressBar extends Vue {
  steps!: Array<Step>;

  currentStep!: Step;
  isMasPrepopulated!: boolean;
}
