import Inputmask from 'inputmask';

interface MaskOptions {
  mask?: string;
  regex?: string;
  options?: Inputmask.Options;
}

type MaskName = 'name' | 'zipCode' | 'address' | 'city' | 'phone'

const createMask = ({ regex, mask, options }: MaskOptions) => (
  Inputmask({
    mask,
    regex,
    placeholder: ' ',
    positionCaretOnTab: false,
    ...options,
  })
);

const maskInput = (el: HTMLInputElement, inputMask: Inputmask.Instance) => {
  inputMask.mask(el);
};

const masks = {
  name: createMask({ regex: '[a-zA-Z ]{0,20}' }),
  zipCode: createMask({ regex: '[0-9]{0,5}' }),
  address: createMask({ regex: '[0-9A-Za-z\\-\\s]+' }),
  city: createMask({ regex: '[A-Za-z\\s]+' }),
  creditCardNumber: createMask({ mask: '9999 9999 9999 9999' }),
  creditCardExpirationDate: createMask({
    mask: '99 / 99',
    options: {
      greedy: false,
    },
  }),
  ssn: createMask({ regex: '[0-9]{0,4}' }),
  cvc: createMask({ regex: '[0-9]{0,4}' }),
  phone: createMask({
    mask: '(299) 299-9999',
    options: {
      definitions: {
        2: { validator: '[2-9]' },
        1: { validator: '[1]' },
      },
      showMaskOnFocus: false,
      showMaskOnHover: false,
      positionCaretOnTab: false,
    },
  }),
};

export {
  MaskName,
  maskInput,
  masks,
};
