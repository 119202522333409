


























































































































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

// APIs
import CheckoutApi from '../apis/checkout-api';
import { payloadStepCompletedShipping } from '../apis/payloads/checkout-api';

// Components
import SupportPhone from '../components/SupportPhone.vue';
import AddressCard from '../components/AddressCard.vue';
import AddressForm from '../components/form/AddressForm.vue';
import Codeword from '../components/form/Codeword.vue';
import TextField from '../components/form/TextField.vue';
import DateField from '../components/form/DateField.vue';
import SectionCard from '../components/SectionCard.vue';
import { ValidateLocationModal } from '../components/modals';
import StepActions from '../components/StepActions.vue';
import Tooltip from '../components/Tooltip.vue';
import Icon from '../components/Icon.vue';

// Interfaces
import { Order, OrderAddress, OrderStep } from '../interfaces';
import { ValidateLocation } from '../interfaces/validate-location';

// Mixins
import OrderValidationMixin from '../mixins/orderValidationMixin';

// Utils
import { isValidAddress } from '../utils/address-validations';
import { isValidActivationDate, notBeforeAfterEightOClock } from '../utils/date-validations';
import { pushGAFunnel } from '../utils/gtm'
import { mixpanelTrackStep } from '../utils/mixpanel';

@Component({
  mixins: [OrderValidationMixin],
  components: {
    SupportPhone,
    AddressCard,
    AddressForm,
    Codeword,
    TextField,
    SectionCard,
    ValidationObserver,
    ValidateLocationModal,
    StepActions,
    DateField,
    Tooltip,
    Icon,
  },
  computed: {
    ...mapGetters(['nextStepPath', 'shippingAddress', 'isMasPrepopulated','isDIYOrder']),
    ...mapState(['currentOrder', 'isCustomerCheckingOut', 'isSubmitting']),
  },
  methods: {
    isValidActivationDate, notBeforeAfterEightOClock,

    ...mapMutations([
      'updateCurrentOrder',
      'orderSubmitting',
      'orderSubmittingComplete',
      'unlockNextStep',
    ]),
  },
})
export default class Shipping extends Vue {
  addressValidations!: ValidateLocation;
  checkoutApi = new CheckoutApi();
  currentOrder!: Order;
  emailLabel = "Email Address";
  activationDateText = "Preferred Activation Date";
  isButtonSpinning = true;
  isCustomerCheckingOut!: boolean;
  isMasPrepopulated!: boolean;
  isSubmitting!: boolean;
  isValidAddress = true;
  nextStepPath!: string;
  orderSubmitting!: Function;
  orderSubmittingComplete!: Function;
  shippingAddress!: OrderAddress;
  unlockNextStep!: Function;
  updateCurrentOrder!: Function;
  isDIYOrder!: boolean;
  continueButtonText = '';

  async validateAddress() {
    const address = this.shippingAddress;
    delete this.shippingAddress.cleanStreetAddress;
    delete this.shippingAddress.originalAddress1;
    this.addressValidations = await this.checkoutApi.validateAddress(address);
    if (this.isMasPrepopulated) {
      this.addressValidations.success = true;
      this.addressValidations.isValid = true;
      this.addressValidations.cleanStreetAddress = undefined;
    }
    this.shippingAddress.originalAddress1 = this.shippingAddress.address1;
    this.isValidAddress = !!isValidAddress({
      validations: this.addressValidations,
      address: address!,
      isCustomerCheckingOut: this.isCustomerCheckingOut,
      useShippingAddress: false,
      step: 'shipping',
      isMasPrepopulated: this.isMasPrepopulated,
    });
  }

  async submit() {
    this.orderSubmitting();
    await this.validateAddress();
    if (this.isValidAddress) {
      this.stepCompleted();
    } else {
      if (!this.isMasPrepopulated) {
        this.orderSubmittingComplete();
      } else {
        this.isButtonSpinning = false;
      }
      ($((this.$refs.validateLocationModal as Vue).$el) as any).modal('show');
    }
  }

  stepCompleted() {
    this.isValidAddress = true;
    this.completeStep();
    this.unlockNextStep(OrderStep.SHIPPING);
    this.$router.push(this.nextStepPath);
  }

  async completeStep() {
    this.updateCurrentOrder(this.currentOrder);
    const payload = payloadStepCompletedShipping(this.currentOrder)
    const response = await this.checkoutApi.completeStep(this.currentOrder, OrderStep.SHIPPING, payload);

    this.updateCurrentOrder({
      total: response.total,
      salesTax: response.salesTax,
      netsuiteQuoteId: response.netsuiteQuoteId,
      isEligibleForAffirmFinancing: response.isEligibleForAffirmFinancing,
      financingMonthlyTotal: response.financingMonthlyTotal,
      financingMonths: response.financingMonths,
      step: response.step,
    });
  }

  mounted() {
    pushGAFunnel(2, this.currentOrder);
    mixpanelTrackStep('Shipping', this.currentOrder);
    this.orderSubmittingComplete();

    if (this.isMasPrepopulated) {
      this.emailLabel = "Email";
      this.activationDateText = "Preferred Activation Date";
    }
  }
}

