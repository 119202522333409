import { SelectBoxOption } from '@/interfaces';

const options: Array<SelectBoxOption> = [
  { id: 3525, name: 'Alabama', abbr: 'AL' },
  { id: 3524, name: 'Alaska', abbr: 'AK' },
  { id: 3527, name: 'American Samoa', abbr: 'AS' },
  { id: 3528, name: 'Arizona', abbr: 'AZ' },
  { id: 3526, name: 'Arkansas', abbr: 'AR' },
  { id: 3529, name: 'California', abbr: 'CA' },
  { id: 3530, name: 'Colorado', abbr: 'CO' },
  { id: 3531, name: 'Connecticut', abbr: 'CT' },
  { id: 3533, name: 'Delaware', abbr: 'DE' },
  { id: 3532, name: 'District of Columbia', abbr: 'DC' },
  { id: 3534, name: 'Florida', abbr: 'FL' },
  { id: 3535, name: 'Georgia', abbr: 'GA' },
  { id: 3537, name: 'Hawaii', abbr: 'HI' },
  { id: 3539, name: 'Idaho', abbr: 'ID' },
  { id: 3540, name: 'Illinois', abbr: 'IL' },
  { id: 3541, name: 'Indiana', abbr: 'IN' },
  { id: 3538, name: 'Iowa', abbr: 'IA' },
  { id: 3542, name: 'Kansas', abbr: 'KS' },
  { id: 3543, name: 'Kentucky', abbr: 'KY' },
  { id: 3544, name: 'Louisiana', abbr: 'LA' },
  { id: 3547, name: 'Maine', abbr: 'ME' },
  { id: 3546, name: 'Maryland', abbr: 'MD' },
  { id: 3545, name: 'Massachusetts', abbr: 'MA' },
  { id: 3548, name: 'Michigan', abbr: 'MI' },
  { id: 3549, name: 'Minnesota', abbr: 'MN' },
  { id: 3552, name: 'Mississippi', abbr: 'MS' },
  { id: 3550, name: 'Missouri', abbr: 'MO' },
  { id: 3553, name: 'Montana', abbr: 'MT' },
  { id: 3556, name: 'Nebraska', abbr: 'NE' },
  { id: 3560, name: 'Nevada', abbr: 'NV' },
  { id: 3557, name: 'New Hampshire', abbr: 'NH' },
  { id: 3558, name: 'New Jersey', abbr: 'NJ' },
  { id: 3559, name: 'New Mexico', abbr: 'NM' },
  { id: 3561, name: 'New York', abbr: 'NY' },
  { id: 3554, name: 'North Carolina', abbr: 'NC' },
  { id: 3555, name: 'North Dakota', abbr: 'ND' },
  { id: 3562, name: 'Ohio', abbr: 'OH' },
  { id: 3563, name: 'Oklahoma', abbr: 'OK' },
  { id: 3564, name: 'Oregon', abbr: 'OR' },
  { id: 3565, name: 'Pennsylvania', abbr: 'PA' },
  { id: 3566, name: 'Puerto Rico', abbr: 'PR' },
  { id: 3567, name: 'Rhode Island', abbr: 'RI' },
  { id: 3568, name: 'South Carolina', abbr: 'SC' },
  { id: 3569, name: 'South Dakota', abbr: 'SD' },
  { id: 3570, name: 'Tennessee', abbr: 'TN' },
  { id: 3571, name: 'Texas', abbr: 'TX' },
  { id: 3572, name: 'United States Minor Outlying Islands', abbr: 'UM' },
  { id: 3573, name: 'Utah', abbr: 'UT' },
  { id: 3576, name: 'Vermont', abbr: 'VT' },
  { id: 3574, name: 'Virginia', abbr: 'VA' },
  { id: 3575, name: 'Virgin Islands', abbr: 'VI' },
  { id: 3577, name: 'Washington', abbr: 'WA' },
  { id: 3579, name: 'West Virginia', abbr: 'WV' },
  { id: 3578, name: 'Wisconsin', abbr: 'WI' },
  { id: 3580, name: 'Wyoming', abbr: 'WY' },
];

export default options;
