var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"mode":_vm.validationMode,"rules":_vm.validators},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-0",class:{ 'has-danger': errors.length > 0 }},[_c('label',{staticClass:"text-capitalize",attrs:{"for":_vm.$attrs.id}},[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('span',{class:{ required: _vm.required }},[_vm._v("*")]):_vm._e(),_c('br'),_c('select',_vm._b({class:{
        'form-control': true,
        'custom-select': true,
        'required': _vm.required,
        'is-invalid': errors.length > 0,
      },staticStyle:{"border-width":"2px!important"},attrs:{"name":_vm.name},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.updateValue($event.target.value)}}},'select',_vm.$attrs,false),[(_vm.showEmpty)?_c('option'):_vm._e(),_vm._l((_vm.options),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])})],2),_c('ul',{staticClass:"invalid-feedback m-0",attrs:{"for":_vm.$attrs.id}},_vm._l((errors),function(error){return _c('li',{key:error.index,staticClass:"small"},[_vm._v(_vm._s(error))])}),0),(errors.length === 0)?_c('div',{staticClass:"small"},[_vm._v(_vm._s("\xa0"))]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }