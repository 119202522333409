




import { Vue, Component, Prop } from 'vue-property-decorator';

import Amex from './credit-card-icons/Amex.vue';
import Diners from './credit-card-icons/Diners.vue';
import Mastercard from './credit-card-icons/Mastercard.vue';
import Visa from './credit-card-icons/Visa.vue';
import Discover from './credit-card-icons/Discover.vue';

@Component({
  components: {
    Amex,
    Diners,
    Mastercard,
    Visa,
    Discover,
  },
})
export default class CreditCardIcon extends Vue {
  @Prop(String) readonly creditCardType!: 'amex' | 'diners' | 'mastercard' | 'visa' | 'discover'

  get validCreditCardType() {
    return ['amex', 'diners', 'mastercard', 'visa', 'discover'].includes(this.creditCardNormalizedName);
  }

  get creditCardNormalizedName() {
    const type = this.creditCardType.toLowerCase()

    return {
      'american express': 'amex',
    }[type] ?? type
  }
}
