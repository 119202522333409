import { State } from '@/interfaces';

export default {
  errorMessage: null,
  infinityPhoneNumber: '866.956.9228',
  isSalesRepCheckingOut: false,
  isAuthorizedPartnerCheckingOut: false,
  isCustomerCheckingOut: false,
  isFieldSalesPartnerCheckingOut: false,
  steps: [],
  isSubmitting: false,
  isOrderAlreadyProcessed: false,
  contractRecipientEmail: '',
  contractRecipientPhone: '',
  acceptedSmsTerms: false,
  currentOrder: {
    id: '',
    total: 0,
    store: '',
    subtotal: 0,
    salesTax: undefined,
    financingMonthlyTotal: 0,
    financingMonths: 0,
    subsidizingMonthlyTotal: 0,
    discountTotal: 0,
    cpqQuoteId: '',
    isEligibleForAffirmFinancing: false,
    isEligibleForSubsidizing: false,
    contractUrl: '',
    financingUrl: '',
    financingStatus: undefined,
    netsuiteQuoteId: undefined,
    qualifiedTier: undefined,
    isFinanced: false,
    isSubsidized: false,
    authToken: '',
    checkoutAs: 'customer',
    shipping: {
      name: 'Shipping (2-day)',
      price: 0,
      netsuiteId: 0,
    },
    package: undefined,
    discounts: [],
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      alternativePhone: '',
      netsuiteCustomerId: 0,
      ismasprepopulated: false,
      codeword: '',
    },
    customerSsn: '',
    salesRep: {
      email: '',
      firstName: '',
      lastName: '',
      netsuiteId: 0,
    },
    creditCard: undefined,
    addresses: [],
    memo: '',
    step: undefined,
    monitoringInformation: {
      firstName: '',
      lastName: '',
      primaryPhone: '',
      secondaryPhone: '',
    },
    featureFlags: [],
    isEligibleForPayCheck: false,
    defaultNonce:'',
    additionalNonce:'',
    defaultAmount:'',
    additionalAmount:'',

    isEligibleForSplitPayment: false,
    isSplitPayment: false,
    isEligibleForSunnovaFinancing: false,
    financingReferenceNumber: undefined,
    authorizationInformation: {
      isUnderstandCancelationPolicy : 'true',
      isUnderstandSignAgreement : 'true',
    },
  },
} as State;
