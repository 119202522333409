














































































































































































































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

// Utils
import pluralize from 'pluralize';

// Interfaces
import { Order } from '../interfaces';

// Components
import Tooltip from './Tooltip.vue';
import Icon from './Icon.vue';

@Component({
  components: {
    Tooltip,
    Icon,
  },
  computed: {
    ...mapState([
      'currentOrder',
      'isSalesRepCheckingOut',
    ]),

    ...mapGetters([
      'orderHasService',
      'servicesTotal',
      'allInMonthlyPrice',
      'monitoringOnlyOrder',
      'isMasPrepopulated',
      'isDIYOrder',
    ]),
  },
})
export default class LineItemsTable extends Vue {
  currentOrder!: Order;

  orderHasService!: boolean;

  servicesTotal!: number;

  monitoringOnlyOrder!: boolean

  isSalesRepCheckingOut!: boolean;

  isMasPrepopulated!: boolean

  isDIYOrder!: boolean;

  formatPrice(price: number, currency = 'USD', precision = 2) {
    const formatter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: precision,
    });

    return formatter.format(price);
  }

  get products() {
    return this.currentOrder.itemAddons ?? [];
  }


  get discounts() {
    return this.currentOrder.discounts ?? [];
  }

  itemPrice(product: any) {
    return (product.price - (product.discountPerUnit ?? 0));
  }

  itemTotal(product: any) {
    return (product.price - (product.discountPerUnit ?? 0)) * (product.quantity > 0 ? product.quantity : 1);
  }

  packagePrice(currentPackage: any) {
    return currentPackage.price - (currentPackage?.discountPerUnit ?? 0);
  }

  pluralizeName(name: string, quantity: number) {
    return pluralize(name, quantity);
  }

  get tooltipTitle() {
    return this.isDIYOrder ? "Price excludes taxes and other fees. Charges begin the earlier of activation or 30 days from purchase."+
    " Purchase fulfillment requires execution of 36 - month monitoring contract." : "Your first monthly payment is charged when you activate your system.";
  }
}
