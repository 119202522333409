



























































































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

// Interfaces
import { Order, OrderStep } from '../../interfaces';

@Component({
  computed: {
    ...mapState([
      'currentOrder',
      'isAuthorizedPartnerCheckingOut',
      'contractRecipientEmail',
      'contractRecipientPhone',
      'isCustomerCheckingOut',
    ]),
  },
})
export default class AgreementModal extends Vue {
  currentOrder!: Order;

  isAuthorizedPartnerCheckingOut!: boolean;

  contractRecipientEmail!: string;

  contractRecipientPhone!: string;
  
  isCustomerCheckingOut!: boolean;

  get email() {
    return this.currentOrder?.customer.email;
  }

  next() {
    if (!this.isCustomerCheckingOut && this.currentOrder.postSaleManagerUrl && !this.currentOrder.isEligibleForPayCheck)
      window.location.href = this.currentOrder.postSaleManagerUrl;
    else
      this.$router.push(OrderStep.COMPLETE);
  }
}
