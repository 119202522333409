import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { Step } from '../interfaces';

declare module 'vue/types/vue' {
  interface Vue {
    beforeRouteLeave(to: any, from: any, next: any): Function | any;
  }
}

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  computed: {
    ...mapGetters([
      'lastUnlockedStep',
    ]),
    ...mapState([
      'steps',
      'isSubmitting',
    ]),
  },
})

export default class OrderValidationMixin extends Vue {
  steps!: Array<Step>;

  isSubmitting!: boolean;

  lastUnlockedStep!: Step;

  async beforeRouteLeave(to: any, from: any, next: any) {
    const fromPath = from.path.split('/').pop();
    const fromStep = this.steps.find((s) => s.path === fromPath);
    if (!this.isSubmitting && fromStep !== this.lastUnlockedStep) {
      const isValid = await (this.$refs.form as Vue & { validate: () => boolean }).validate();
      if (!isValid) {
        return;
      }
    }
    next();
  }
}
