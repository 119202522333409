


































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { SelectBoxOption } from '../../interfaces';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class SelectBox extends Vue {
  @Prop(Boolean) readonly showEmpty!: boolean

  @Prop(String) readonly label!: string

  @Prop([String, Number]) readonly value!: string | number

  @Prop({ default: '' }) readonly validationRules!: string

  @Prop({ default: 'eager' }) readonly validationMode!: string

  @Prop(Array) readonly options!: Array<SelectBoxOption>

  @Prop(Boolean) readonly required!: boolean

  updateValue(value: string) {
    this.$emit('input', value);
  }

  get name() {
    return this.$attrs.name || this.label;
  }

  get validators(): string {
    if (!this.required) {
      return this.validationRules;
    }

    const splitValidators = (this.validationRules && this.validationRules.split('|')) || [];
    if (!splitValidators.includes('required')) {
      splitValidators.unshift('required'); // frontload
    }

    return splitValidators.join('|');
  }
}
