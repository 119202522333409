




















// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// @ts-ignore
import Popover from 'shared-assets/src/js/modules/popover.bootstrap';

// Components
import Icon from './Icon.vue';
import { Order } from '../interfaces';

@Component({
  components: {
    Icon,
  },
  computed: {
    ...mapGetters(['isMasPrepopulated']),
    ...mapState(['currentOrder', 'infinityPhoneNumber', 'isAuthorizedPartnerCheckingOut']),
  },
})
export default class PopOverCallUs extends Vue {
  infinityPhoneNumber!: string;

  isAuthorizedPartnerCheckingOut!: boolean;

  isMasPrepopulated!: boolean;

  currentOrder!: Order;

  mounted() {
    Popover.init();
  }

  get dispatchSupportInfo(): string {
    const supportPhone = this.currentOrder?.customer.supportPhone;
    return ` 
      <div class='t-dark my-2 px-1'>
        <svg class='icon icon-support icon-2 align-text-bottom mr-1'>
          <use xlink:href='#icon-support'></use>
        </svg>
        <span><a href='tel:${supportPhone}'>${supportPhone}</a></span><br/>
      </div>
    `;
  }

  get popoverContent(): string {
    const phoneNumberText = this.isAuthorizedPartnerCheckingOut ? '800.603.0316' : this.infinityPhoneNumber;
    return `
      <div class='t-dark my-2 px-1'>
        <svg class='icon icon-support icon-2 align-text-bottom mr-1'>
          <use xlink:href='#icon-support'></use>
        </svg>
        <span class='mr-1'>Support:</span><br/>
        <span>Call: <a href='tel:800.447.9239'>800.447.9239</a></span><br/>
        <span>Text: <a href='sms:+14695138685' class='sms-link'>469.513.8685</a></span>
      </div>
      <div class='t-dark my-2 px-1'>
        <svg class='icon icon-phone icon-2 align-text-bottom mr-1'>
          <use xlink:href='#icon-phone'></use>
        </svg>
        <span class='mr-1'>Sales:</span><br/>
        <span>Call: 
          <a
            href='tel:${phoneNumberText}
            class='PhoneNumber7682 text-white mb-0'
          >
            ${phoneNumberText}
          </a>
        </span><br/>
        <span>Text: <a href='sms:+14695514997' class='sms-link'>469.551.4997</a></span>
      </div>
    `;
  }
}
