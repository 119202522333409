import { OrderAddress, StepName } from '@/interfaces';
import { ValidateLocation } from '@/interfaces/validate-location';

const addressNeedsCorrection = (address: OrderAddress, cleanStreetAddress?: string) => (
  cleanStreetAddress && cleanStreetAddress?.toLowerCase() !== address.address1.toLowerCase()
);

type Props = {
  validations: ValidateLocation;
  address: OrderAddress;
  useShippingAddress: boolean;
  isCustomerCheckingOut: boolean;
  step: StepName;
  isMasPrepopulated?: boolean;
}

const isValidAddress = ({
  validations,
  address,
  useShippingAddress,
  isCustomerCheckingOut,
  step,
  isMasPrepopulated,
}: Props) => {
  const {
    success,
    cleanStreetAddress,
    isValid,
    isMonitored,
  } = validations;

  const needCorrection = addressNeedsCorrection(address!, cleanStreetAddress);

  if (step === 'shipping') {

    if (isMasPrepopulated) {
      if (isMonitored) {
        return true;
      }
      return false;
    }

    if (isMonitored) {
      return isCustomerCheckingOut;
    }
  }

  if (step === 'monitoring' && useShippingAddress && !isMonitored) {
    return true;
  }

  return success &&
    isValid &&
    !isMonitored &&
    !needCorrection;
};

export { addressNeedsCorrection, isValidAddress };
