import { ContactRecipient, Order } from "@/interfaces";
import moment from "moment";

const payloadStepCompletedShipping = (order: Order) => ({
  customer: order.customer,
  shippingAddress: order.addresses?.find(a => a.kind === 'ship'),
  customerPreferredActivateDate: order.customerPreferredActivateDate
    ? moment(order.customerPreferredActivateDate).format('YYYY-MM-DD HH:mm:ss')
    : null,
})

const payloadStepCompletedMonitoring = (order: Order) => {
  const payload = {
    customer: order.customer,
    monitoringAddress: order.addresses?.find(a => a.kind === 'monitor'),
    monitoringInformation: order.monitoringInformation,
  customerPreferredActivateDate: order.customerPreferredActivateDate
    ? moment(order.customerPreferredActivateDate).format('YYYY-MM-DD HH:mm:ss')
    : null,
  }

  if(payload.monitoringInformation && order.isPayCheck === false){
    delete payload.monitoringInformation.payChecks;
  }

  return payload
}

const payloadStepCompletedPayment = (order: Order) => {
  const payload = {
    customerSsn: order.customerSsn,
    memo: order.memo,
    billingAddress: order.addresses?.find(a => a.kind === 'bill'),
    isFinanced: order.isFinanced,
    isSubsidized: order.isSubsidized,
    isSunnovaFinanced: order.isSunnovaFinanced,
    financingReferenceNumber: order.isSunnovaFinanced ? order.financingReferenceNumber : null,
    financingStatus: order.financingStatus,
    monitoringInformation: order.monitoringInformation,
    isSplitPayment: order.isSplitPayment || !!order.additionalCreditCard,
    authorizationInformation: {
      isUnderstandCancelationPolicy: order.authorizationInformation?.isUnderstandCancelationPolicy === 'true',
      isUnderstandSignAgreement: order.authorizationInformation?.isUnderstandSignAgreement === 'true',
    },
  }

  if(payload.monitoringInformation && order.isPayCheck === false){
    delete payload.monitoringInformation.payChecks;
  }

  return payload
}

const payloadStepCompletedReview = (order: Order, contractRecipient?: ContactRecipient) => {
  if (contractRecipient) {
    let {email} = order.customer
    if (email !== contractRecipient.email) {
      email = contractRecipient.email;
    }

    let phone = order.addresses?.find(a => a.kind === 'monitor')?.phone;
    if (phone !== contractRecipient.phone) {
      phone = contractRecipient.phone;
    }

    const payload = {
      contractRecipient: {
        phone,
        email,
        acceptedSmsTerms: !!contractRecipient.acceptedSmsTerms,
      },
      monitoringInformation: order.monitoringInformation,
      authorizationInformation: order.authorizationInformation,
    }

    if(payload.monitoringInformation && order.isPayCheck === false){
      delete payload.monitoringInformation.payChecks;
    }

    return payload
  }

  return {}
}
const payloadFinancingStatusChanged = (financingStatus: string, isFinanced: boolean) => ({
  financingStatus,
  isFinanced,
})

export {
  payloadStepCompletedShipping,
  payloadStepCompletedMonitoring,
  payloadStepCompletedPayment,
  payloadStepCompletedReview,
  payloadFinancingStatusChanged,
}
