






// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';

// Components
@Component({
  components: {
  },
})

export default class SupportPhone extends Vue {
  @Prop(String) readonly supportPhone!: string
}
