











































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// @ts-ignore
import { PageHelper, ScrollFade, CollapseExtensions } from 'shared-assets';

// Interfaces
import { Order } from '../interfaces';

// Components
import PopOverCallUs from './PopOverCallUs.vue';

@Component({
  components: {
    PopOverCallUs,
  },
  computed: {
    ...mapState([
      'currentOrder',
      'isCustomerCheckingOut',
    ]),
    ...mapGetters([
      'isDIYOrder',
    ]),
  },
})
export default class Navbar extends Vue {
  currentOrder!: Order;
  isCustomerCheckingOut!: boolean;
  isDIYOrder!: boolean;

  fixedContentHeight = 150;

  setContentHeight() {
    this.fixedContentHeight = (this.$refs.fixedContent as HTMLElement).clientHeight;
  }

  mounted() {
    this.setContentHeight();
    window.addEventListener('resize', this.setContentHeight);
    CollapseExtensions.init();
    PageHelper.initMainNavLinkState();

    // @ts-ignore
    if (this.scrollFade) ScrollFade.init();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.setContentHeight);

    // @ts-ignore
    if (this.scrollFade) ScrollFade.deactivate();
  }

  updated() {
    PageHelper.initMainNavLinkState();

    // HACKish
    // Keep navbar from remaining `scrollFade`-ed on new route.
    // Not great to use jQuery for this. Should make a
    // Vue-based version of ScrollFade at somepoint.

    // @ts-ignore
    if (!this.scrollFade) {
      // @ts-ignore
      $(this.$refs.navbarMain).show();
    }

    this.setContentHeight();
  }

  get salesRepName() {
    const salesRep = this.currentOrder.salesRep!;
    return `${salesRep?.firstName} ${salesRep?.lastName}`;
  }
}
