import { SelectBoxOption } from '@/interfaces';

const options: Array<SelectBoxOption> = [
  { id: 1, name: '1', abbr: '1' },
  { id: 2, name: '2', abbr: '2' },
  { id: 3, name: '3', abbr: '3' },
  { id: 4, name: '4', abbr: '4' },
  { id: 5, name: '5', abbr: '5' },
  { id: 6, name: '6', abbr: '6' },
  { id: 7, name: '7', abbr: '7' },
  { id: 8, name: '8', abbr: '8' },
  { id: 9, name: '9', abbr: '9' },
  { id: 10, name: '10', abbr: '10' },
];

export default options;
