














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SectionCard extends Vue {
  @Prop(String) readonly title!: string
}
