


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { uniqueId } from 'lodash-es';

// Components
import SectionCard from '../SectionCard.vue';
import CreditCardIcon from '../CreditCardIcon.vue';
import TextField from '../form/TextField.vue';
import { OrderCreditCard } from '../../interfaces';

@Component({
  components: {
    SectionCard,
    CreditCardIcon,
    TextField,
  },
  computed: {
    ...mapGetters(['orderRequiresMonitoring']),
  },
})
export default class CreditCard extends Vue {
  @Prop(Object) readonly creditCard!: OrderCreditCard
  @Prop({ default: true }) splitPayment!: boolean
  monitoringCheckId = uniqueId("monitoring_check");

  orderRequiresMonitoring!: boolean

  handleMonitoringCheckChange(e: Event) {
    e.preventDefault()
    return false
  }

  handleRemove() {
    this.$emit('removed');
  }
}
