/**
 * Check if the provided date in format mm/yy expires in 90 days
 * it returns true if date is not valid.
 * @param date date
 * @returns {boolean} if date expires in 90 days
 */
import Payment from 'payment';
/* eslint-disable */

const indexOf =
  [].indexOf ||
  function(item: any) {
    for (var i = 0, l = item.length; i < l; i++) {
      if (i in item && item[i] === item) return i;
    }
    return -1;
  };

const cardFromNumber = (num: string) => {
  let card: any = '';
  let foundCard: Array<string> | undefined = [];
  let j: number = 0;
  let len: number = 0;
  let match: RegExpMatchArray | null = [];
  num = (num + '').replace(/\D/g, '');
  foundCard = void 0;
  for (j = 0, len = (Payment as any).getCardArray().length; j < len; j++) {
    card = (Payment as any).getCardArray()[j];
    if ((match = num.match(card.pattern))) {
      if (!foundCard || match[0].length > foundCard[1][0].length) {
        foundCard = [card, match];
      }
    }
  }
  return foundCard && foundCard[0];
};

export const expIn90Days = (date: string) => {
  if (!date) return true;
  try {
    const month = date.split('/')[0].trim();
    const year = date.split('/')[1].trim();
    const expDate = new Date(Number(`20${year}`), Number(month), 0).getTime();
    const today = new Date().getTime();
    return Math.round(Math.abs(expDate - today) / (1000 * 60 * 60 * 24)) < 90;
  } catch (err) {
    return true;
  }
};

export const capitalizeCreditCardType = (creditCardType: string | null) => {
  if (typeof creditCardType !== 'string') return '';
  return creditCardType.charAt(0).toUpperCase() + creditCardType.slice(1);
};

export const getCardExpiration = (expirationDate: string) => {
  const expiration = expirationDate.split(' / ', 2);
  return { month: expiration[0], year: expiration[1] };
};

export const validateCardLowEnv = (cardNumber: any) => {
  let card: Array<string> | string | undefined = '';
  let ref: any;
  cardNumber = (cardNumber + '').replace(/\s+|-/g, '');
  if (!/^\d+$/.test(cardNumber)) {
    return false;
  }
  card = cardFromNumber(cardNumber);
  if (!card) {
    return false;
  }
  // @ts-ignore
  return (ref = cardNumber.length), indexOf.call(card.length, ref) >= 0;
};
