import { Order } from '@/interfaces';
import mixpanel from 'mixpanel-browser';
import config from '../config';

const useMixpanel = !!config.mixPanelToken

if (useMixpanel) {
  mixpanel.init(config.mixPanelToken, {
    debug: true,
    ignore_dnt: true,
  });
}

export const mixpanelTrack = (eventName: string, payload: any) => {
  if (useMixpanel) {
    mixpanel.track(eventName, payload)
  }
}

export const mixpanelTrackStep = (step: string, currentOrder: Order) => {
  mixpanelTrack(`CHECKOUT-${config.currentEnvironment}`, {
    'Step': step,
    'Id': currentOrder?.id,
    'VId': currentOrder?.customer?.vId,
    'Store': currentOrder?.store,
    'CheckoutAs': currentOrder?.checkoutAs,
  })
}
