






































// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

// Interfaces
import { Order } from '../../interfaces';

@Component({
  computed: {
    ...mapState(['currentOrder']),
  },
})
export default class ConfirmFinancingModal extends Vue {
  @Prop()
  submit!: Function;

  @Prop()
  dismissModal!: Function;

  @Prop()
  isCustomerCheckingOut!: boolean;

  currentOrder!: Order;

  submitForm() {
    this.dismissModal();
    this.submit();
  }

  get modalText() {
    if (this.isCustomerCheckingOut) {
      return "We're going to generate the financing application and will redirect you to the Affirm site"
    }

    return "We're going to send an email to the customer with a link to the financing application"
  }
}
