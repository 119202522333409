


















































































































































// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// Utils
import pluralize from 'pluralize';

// Components
import CartSummaryItem from './CartSummaryItem.vue';
import AnimatedNumber from './AnimatedNumber.vue';
import Tooltip from './Tooltip.vue';
import Icon from './Icon.vue';

// Interfaces
import { Order, OrderItem } from '../interfaces';

@Component({
  components: {
    AnimatedNumber,
    CartSummaryItem,
    Tooltip,
    Icon,
  },
  computed: {
    ...mapState([
      'currentOrder',
    ]),
    ...mapGetters([
      'orderHasSystem',
      'servicesTotal',
      'monitoringOnlyOrder',
      'todayTotal',
    ]),
  },
})
export default class MobileCartSummary extends Vue {
  @Prop({ default: 'mb-0', required: false }) readonly rootClasses!: string

  orderHasSystem!: Function

  monitoringOnlyOrder!: boolean

  currentOrder!: Order

  servicesTotal!: number

  get title() {
    return this.orderHasSystem ? 'Your system' : 'Your order';
  }

  get products() {
    return this.currentOrder.itemAddons ?? [];
  }

  hasDiscount(item: OrderItem) {
    return (item.discountPerUnit ?? 0) > 0;
  }

  lineItemTotal(item: OrderItem) {
    const { price, quantity, discountPerUnit } = item;
    return (price - (discountPerUnit ?? 0)) * quantity;
  }

  pluralizeName(name: string, quantity: number) {
    return pluralize(name, quantity);
  }
}
