















import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
  model: {
    prop: 'isChecked',
    event: 'input',
  },
})
export default class Checkbox extends Vue {
  @Prop(String) readonly label!: string;

  @Prop(Boolean) readonly isChecked!: boolean;

  get name() {
    return this.$attrs.name || this.label;
  }

  updateValue(value: boolean) {
    this.$emit('input', value);
  }
}
