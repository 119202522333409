

















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

// Components
import dropin from 'braintree-web-drop-in';
import Tooltip from '../components/Tooltip.vue';
import CreditCardIcon from '../components/CreditCardIcon.vue';
import AddressForm from '../components/form/AddressForm.vue';
import TextField from '../components/form/TextField.vue';
import TextAreaField from '../components/form/TextAreaField.vue';
import Checkbox from '../components/form/Checkbox.vue';
import SectionCard from '../components/SectionCard.vue';
import PaymentOptions from '../components/PaymentOptions.vue';
import StepActions from '../components/StepActions.vue';
import Alert from '../components/Alert.vue';
import Icon from '../components/Icon.vue';
import CreditCards from '../components/payment/CreditCards.vue';
import NewCreditCard from '../components/payment/NewCreditCard.vue';
import CustomerAuthorization from '../components/customer-authorization/CustomerAuthorization.vue';
import { ValidateLocationModal, ConfirmFinancingModal } from '../components/modals';

// APIs
import CheckoutApi from '../apis/checkout-api';
import {
  payloadStepCompletedPayment,
  payloadFinancingStatusChanged,
} from '../apis/payloads/checkout-api';

// Interfaces
import { Order, OrderAddress, OrderFinancingStatus, OrderStep } from '../interfaces';
import { ValidateLocation } from '../interfaces/validate-location';

// Data
import newAddress from '../constants/new-address';

// Mixins
import OrderValidationMixin from '../mixins/orderValidationMixin';

// Utils
import { pushGAFunnel } from '../utils/gtm';
import { isValidAddress } from '../utils/address-validations';
import { mixpanelTrackStep } from '../utils/mixpanel';
import config from '../config';

@Component({
  mixins: [OrderValidationMixin],
  components: {
    ConfirmFinancingModal,
    ValidateLocationModal,
    AddressForm,
    CreditCardIcon,
    CreditCards,
    NewCreditCard,
    Tooltip,
    TextField,
    TextAreaField,
    SectionCard,
    PaymentOptions,
    Checkbox,
    ValidationObserver,
    StepActions,
    Alert,
    Icon,
    CustomerAuthorization,
  },
  computed: {
    ...mapGetters([
      'nextStepPath',
      'servicesTotal',
      'billingAddress',
      'shippingAddress',
      'isFeatureFlagActive',
      'orderCanBePlaced',
      'isFinancingInProgress',
      'orderHasService',
      'isMasPrepopulated',
      'isSplitPaymentAllowed',
      'isDIYOrder',
      'hasValidCustomerAuthorizationAnswers',
    ]),
    ...mapState([
      'currentOrder',
      'isCustomerCheckingOut',
      'isSalesRepCheckingOut',
      'isSubmitting',
      'errorMessage',
    ]),
  },
  methods: {
    ...mapMutations([
      'updateCurrentOrder',
      'updateErrorMessage',
      'orderSubmitting',
      'orderSubmittingComplete',
      'unlockNextStep',
      'blockNextSteps',
    ]),
  },
})
export default class PaymentView extends Vue {
  billingAddress!: OrderAddress;
  shippingAddress!: OrderAddress;
  checkoutApi = new CheckoutApi();
  isSubmitting!: boolean;
  isCustomerCheckingOut!: boolean;
  isSalesRepCheckingOut!: boolean;
  isFeatureFlagActive!: Function;
  isFinancingInProgress!: boolean;
  orderCanBePlaced!: boolean;
  currentOrder!: Order;
  orderSubmitting!: Function;
  orderSubmittingComplete!: Function;
  updateCurrentOrder!: Function;
  updateErrorMessage!: Function;
  servicesTotal!: number;
  unlockNextStep!: Function;
  blockNextSteps!: Function;
  nextStepPath!: string;
  errorMessage!: string | null;
  isPaymentCheckInProgress = false;
  orderHasService!: boolean;
  isMasPrepopulated!: boolean;
  creditCardDropin?: dropin.Dropin | null;
  creditCardAmount = '0';
  creditCardUseForMonitoring = true;
  additionalCreditCardDropin?: dropin.Dropin | null;
  additionalCreditCardAmount = '0';
  additionalCreditCardUseForMonitoring = false;
  isSplitPaymentAllowed!: boolean;
  isDIYOrder!: boolean;

  isValidAddress = true
  addressValidations!: ValidateLocation;
  hasValidCustomerAuthorizationAnswers!: Function;

  @Watch('currentOrder')
  onOrderChanged(newOrder: Order, oldOrder: Order) {
    if (newOrder && newOrder.total !== oldOrder.total) {
      this.creditCardAmount = (
        newOrder.total - parseFloat(this.additionalCreditCardAmount)
      ).toString();
    }
  }

  mounted() {
    this.orderSubmittingComplete();
    if (this.currentOrder.isFinanced) {
      this.startFinancingSocket();
    }
    pushGAFunnel(4, this.currentOrder);
    mixpanelTrackStep('Payment', this.currentOrder);
  }

  async update() {
    this.updateCurrentOrder(this.currentOrder);
  }

  toggleUseShippingAsBillingAddress() {
    if (!this.billingAddress) {
      this.useNewAddress();
    } else {
      this.useShippingAddress();
    }
  }

  useShippingAddress() {
    this.updateCurrentOrder({
      ...this.currentOrder,
      addresses: this.currentOrder.addresses?.filter(a => a.kind !== 'bill'),
    });
  }

  useNewAddress() {
    this.updateCurrentOrder({
      ...this.currentOrder,
      addresses: [...(this.currentOrder.addresses ?? []), newAddress('bill')],
    });
  }

  async handlePaymentResult() {
    try {
      await this.completeStep();
    } catch (error) {
      const message = error.response?.data?.error?.message ?? '';
      this.orderSubmittingComplete(
        message.split('UIError:')[1] ?? 'There was an issue submitting this order',
      );
      return;
    }

    if (this.isFinancingInProgress && this.isCustomerCheckingOut && this.currentOrder.financingUrl) {
      window.location.href = this.currentOrder.financingUrl;
      return;
    }

    if (
      !this.currentOrder.isFinanced ||
      this.currentOrder.financingStatus === OrderFinancingStatus.ACTIVE
    ) {
      this.unlockNextStep(OrderStep.PAYMENT);
      this.$router.push(this.nextStepPath);
      this.cleanupBraintree();
    } else {
      this.startFinancingSocket();
      this.blockNextSteps(OrderStep.PAYMENT);
    }
  }

  async submit() {
    this.orderSubmitting();

    if (this.billingAddress) {
      await this.validateAddress()

      if (this.isValidAddress) {
        await this.stepCompleted()
      } else {
        this.orderSubmittingComplete(false);
        ($((this.$refs.validateLocationModal as Vue).$el) as any).modal('show');
      }
    } else {
      await this.stepCompleted()
    }
  }

  buildPreAuthPaymentDetail(nonce: string, amount: number, isMonitoring: boolean) {
    return {
      nonce,
      amount,
      isMonitoring,
    };
  }

  braintreeRequestPayment(instance: any) {
    return new Promise(resolve => {
      instance.requestPaymentMethod(async (requestPaymentMethodErr: any, payload: any) => {
        if (requestPaymentMethodErr) {
          // No payment method is available.
          // An appropriate error will be shown in the UI.
          console.error(requestPaymentMethodErr);
          resolve({ status: false });
        } else if (payload.binData.prepaid === 'Yes') {
          instance.clearSelectedPaymentMethod();
          resolve({
            status: false,
            errorToDisplay: 'Gift cards are not a valid payment method. Please provide a different payment method.',
          });
        } else if (payload.binData.countryOfIssuance !== 'USA') {
          instance.clearSelectedPaymentMethod();
          resolve({
            status: false,
            errorToDisplay: 'This credit card was issued outside the USA. Please provide a different payment method.',
          });
        } else {
          resolve({ status: true, msg: payload });
        }
      });
    });
  }

  async requestPreAuth(data: any) {
    this.isPaymentCheckInProgress = true;

    try {
      await this.checkoutApi.beginPaymentTransaction(this.currentOrder, data);
      await this.handlePaymentResult();
    } catch (error) {
      if (error.message === 'Network Error') {
        this.orderSubmittingComplete(
          'We were unable to communicate with the server. Please try again.',
        );

        if (this.creditCardDropin) {
          this.creditCardDropin.clearSelectedPaymentMethod();
        }
        if (this.additionalCreditCardDropin) {
          this.additionalCreditCardDropin.clearSelectedPaymentMethod();
        }
      } else {
        const message = error.response?.data?.error?.message ?? '';
        this.orderSubmittingComplete(
          message.split('UIError:')[1] ??
            `There was an issue verifying your card: ${message}<br>Please check that the information is correct and try again.`,
        );

        if (this.$refs.creditCardForm) {
          await (this.$refs.creditCardForm as NewCreditCard).startBraintree()
        }
        if (this.$refs.additionalCreditCardForm) {
          await (this.$refs.additionalCreditCardForm as NewCreditCard).startBraintree()
        }
      }

      throw error;
    } finally {
      this.isPaymentCheckInProgress = false;
    }
  }

  async showFinancingModal() {
    if (this.currentOrder.creditCard) {
      if (this.creditCardDropin) {
        await this.creditCardDropin.requestPaymentMethod();
      }
      if (this.additionalCreditCardDropin) {
        await this.additionalCreditCardDropin.requestPaymentMethod();
      }
    }

    const isValid = await (this.$refs.form as any).validate();
    if (!isValid) {
      return;
    }

    ($((this.$refs.confirmFinancingModal as Vue).$el) as any).modal({
      backdrop: 'static',
      keyboard: false,
    });
  }

  dismissConfirmFinancingModal() {
    ($((this.$refs.confirmFinancingModal as Vue).$el) as any).modal('hide');
  }

  startFinancingSocket() {
    if (config.realtimeHubUrl) {
      const connection = new (window as any).signalR.HubConnectionBuilder()
        .withUrl(config.realtimeHubUrl)
        .build();
      connection.on('StatusTracker', this.financingStatusUpdated);
      connection
        .start()
        .then(() => {
          const orderNumber = this.currentOrder.id;
          connection
            .invoke('RegisterOrder', orderNumber)
            .catch((err: any) => console.error(err.toString()));
        })
        .catch((err: any) => console.error(err.toString()));
    } else {
      console.warn('Unable to start financing socket with due to missing config!');
    }
  }

  async financingStatusUpdated(info: string) {
    const orderUpdate = JSON.parse(info);
    const financingStatus = orderUpdate.applicationStatus as OrderFinancingStatus;
    if (financingStatus) {
      const isFinanced = financingStatus !== OrderFinancingStatus.DECLINED;

      this.updateCurrentOrder({
        financingStatus,
        isFinanced,
      });

      const payload = payloadFinancingStatusChanged(financingStatus, isFinanced);
      await this.checkoutApi.completeStep(this.currentOrder, OrderStep.PAYMENT, payload);

      if (financingStatus === OrderFinancingStatus.ACTIVE) {
        this.unlockNextStep(OrderStep.PAYMENT);
        this.$router.push(this.nextStepPath);
      }

      if (financingStatus === OrderFinancingStatus.DECLINED) {
        this.orderSubmittingComplete();
      }
    }
  }

  async completeStep() {
    const payload = payloadStepCompletedPayment(this.currentOrder);
    const response = await this.checkoutApi.completeStep(
      this.currentOrder,
      OrderStep.PAYMENT,
      payload,
    );
    this.updateCurrentOrder({
      creditCard: response.creditCard,
      additionalCreditCard: response.additionalCreditCard,
      step: response.step,
      isFinanced: response.isFinanced,
      isSunnovaFinanced: response.isSunnovaFinanced,
      financingReferenceNumber: response.financingReferenceNumber,
      financingStatus: response.financingStatus,
      financingUrl: response.financingUrl,
    });
  }

  get billingAddressTooltipText() {
    return this.isCustomerCheckingOut
      ? 'If billing address is different from shipping address,\n' +
          'please call (833) 327-4657 to complete your order.\n' +
          'Currently, editing of the billing address for online orders is not available.'
      : '';
  }

  get continueButtonText() {
    const financingText = this.isCustomerCheckingOut ? 'Processing Affirm Application' : 'Waiting for financing';
    const buttonText = this.isFinancingInProgress ? financingText : 'Calculating taxes';
    return this.orderCanBePlaced ? undefined : buttonText;
  }

  get isFinancingModalRequired() {
    return this.currentOrder.isFinanced && !this.currentOrder.financingStatus;
  }

  cleanupBraintree() {
    if (this.creditCardDropin) {
      this.creditCardDropin.teardown();
      this.creditCardDropin = null;
    }
    if (this.additionalCreditCardDropin) {
      this.additionalCreditCardDropin.teardown();
      this.additionalCreditCardDropin = null;
    }
  }

  get displayCreditCards() {
    return (
      this.currentOrder.creditCard &&
      (!this.currentOrder.financingStatus || this.currentOrder.financingStatus === 'Declined')
    );
  }

  handleSplitPayment() {
    this.updateCurrentOrder({ isSplitPayment: true });
    this.creditCardAmount = this.currentOrder.total.toString();
    this.additionalCreditCardAmount = '0';
    this.blockNextSteps(OrderStep.PAYMENT);
  }

  handleRemoveSplitPayment() {
    this.updateCurrentOrder({ isSplitPayment: false });
    this.creditCardAmount = this.currentOrder.total.toString();
    this.additionalCreditCardAmount = '0';
    this.blockNextSteps(OrderStep.PAYMENT);
  }

  handleMonitoringChange() {
    const { creditCard, additionalCreditCard } = this.currentOrder;
    creditCard!.useForMonitoring = !creditCard!.useForMonitoring;
    additionalCreditCard!.useForMonitoring = !additionalCreditCard!.useForMonitoring;

    this.updateCurrentOrder({
      creditCard,
      additionalCreditCard,
    });
  }

  handleNewCardMonitoringChange() {
    this.additionalCreditCardUseForMonitoring = !this.additionalCreditCardUseForMonitoring;
    this.creditCardUseForMonitoring = !this.creditCardUseForMonitoring;
  }

  handleCreditCardAmountChange(value: string) {
    const numberValue = parseFloat(value);
    if (numberValue > this.currentOrder.total) {
      this.creditCardAmount = this.currentOrder.total.toString();
      this.additionalCreditCardAmount = '0';
    } else {
      this.creditCardAmount = value;
      this.additionalCreditCardAmount = (this.currentOrder.total - numberValue).toFixed(2);
    }
  }

  handleAdditionalCreditCardAmountChange(value: string) {
    const numberValue = parseFloat(value);
    if (numberValue > this.currentOrder.total) {
      this.creditCardAmount = '0'
      this.additionalCreditCardAmount = this.currentOrder.total.toString();
    } else {
      this.creditCardAmount = (this.currentOrder.total - numberValue).toFixed(2);
      this.additionalCreditCardAmount = value
    }
  }

  handleCreditCardDropinCreate(instance: dropin.Dropin) {
    this.creditCardDropin = instance;
  }

  handleAdditionalCreditCardDropinCreate(instance: dropin.Dropin) {
    this.additionalCreditCardDropin = instance;
  }

  async validateAddress() {
    this.addressValidations = {
      ...(await this.checkoutApi.validateAddress(this.billingAddress)),
      isMasPrepopulated: false,
      isMonitored: false,
    }
    this.isValidAddress = !!isValidAddress({
      validations: this.addressValidations,
      address: this.billingAddress,
      isCustomerCheckingOut: this.isCustomerCheckingOut,
      useShippingAddress: false,
      step: OrderStep.MONITORING,
      isMasPrepopulated: this.isMasPrepopulated,
    });
  }

  paymentRequestFailure(response: any) {
    if (response.errorToDisplay) {
      this.updateErrorMessage(response.errorToDisplay);
    }

    this.orderSubmittingComplete();
  }

  async stepCompleted() {
    if (this.currentOrder.creditCard) {
      await this.handlePaymentResult();
      return;
    }

    const defaultNonceData: any = await this.braintreeRequestPayment(this.creditCardDropin);
    if (defaultNonceData.status) {
      this.updateCurrentOrder({
        defaultNonce: defaultNonceData.msg.nonce,
      });
    } else {
      this.paymentRequestFailure(defaultNonceData);
      return;
    }

    if (this.currentOrder.isSplitPayment) {
      const nonceData: any = await this.braintreeRequestPayment(this.additionalCreditCardDropin);
      if (nonceData.status) {
        this.updateCurrentOrder({
          additionalNonce: nonceData.msg.nonce,
        });
      } else {
        this.paymentRequestFailure(nonceData);
        return;
      }
    } else {
      this.updateCurrentOrder({
        additionalCreditCard: null,
        additionalNonce: null,
      });
    }
    const primaryCard = this.buildPreAuthPaymentDetail(
      this.currentOrder.defaultNonce!,
      parseFloat(this.creditCardAmount),
      this.creditCardUseForMonitoring,
    );
    const additionalCard = this.currentOrder.additionalNonce
      ? this.buildPreAuthPaymentDetail(
          this.currentOrder.additionalNonce!,
          parseFloat(this.additionalCreditCardAmount),
          this.additionalCreditCardUseForMonitoring,
        )
      : null;

    await this.requestPreAuth({
      primaryPayment: primaryCard,
      additionalPayment: additionalCard,
    });
  }

  get showAdditionalInfo() {
    return !this.isFinancingInProgress && !this.isDIYOrder;
  }
}
