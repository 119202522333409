



























import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';

// Components
import SectionCard from '../SectionCard.vue';
import CreditCard from './CreditCard.vue';
import { Order, OrderStep } from '../../interfaces';

@Component({
  components: {
    SectionCard,
    CreditCard,
  },
  computed: {
    ...mapState(['currentOrder', 'isCustomerCheckingOut', 'isSalesRepCheckingOut', 'isSubmitting']),
    ...mapGetters(['orderRequiresMonitoring']),
  },
  methods: {
    ...mapMutations([
      'updateCurrentOrder',
      'blockNextSteps',
    ]),
  },
})
export default class CreditCards extends Vue {
  currentOrder!: Order
  updateCurrentOrder!: Function;
  blockNextSteps!: Function;
  orderRequiresMonitoring!: boolean;

  handleMonitoringChange() {
    this.$emit("monitoringChanged")
  }

  handleCreditCardRemoval() {
    const {additionalCreditCard} = this.currentOrder
    const creditCard = additionalCreditCard ? {
      ...additionalCreditCard,
      authorizedAmount: this.currentOrder.total,
      useForMonitoring: this.orderRequiresMonitoring,
    } : null

    this.updateCurrentOrder({
      creditCard,
      additionalCreditCard: null,
    })

    this.blockNextSteps(OrderStep.PAYMENT)
  }

  handleAdditionalCreditCardRemoval() {
    const {creditCard} = this.currentOrder

    this.updateCurrentOrder({
      creditCard: {
        ...creditCard,
        authorizedAmount: this.currentOrder.total,
        useForMonitoring: this.orderRequiresMonitoring,
      },
      additionalCreditCard: null,
    })

    this.blockNextSteps(OrderStep.PAYMENT)
  }

  handleSplitPayment() {
    this.blockNextSteps(OrderStep.PAYMENT)
    this.$emit("splitPayment")
  }
}
