






















// Vue
import { Vue, Component } from 'vue-property-decorator';
import config from '@/config';

// Components
import Navbar from '../components/Navbar.vue';

@Component({
  components: {
    Navbar,
  },
})
export default class NotFoundView extends Vue {
  get goBackToSafetyUrl(): string {
    return config.safetyUrl;
  }
}
