const pushDataLayer = (json: any) => {
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push(json)
}

export const pushGAFunnel = (step: number, currentOrder: any) => {
  const funnel = {
    event: 'enhancedEcommerce_checkout',
    ecommerce: {
      checkout: {
        actionField: { step },
        prospectNumber: currentOrder.customer?.prospectNumber,
      },
    },
  }

  pushDataLayer(funnel)
}
