



































// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';

// Interfaces
import { OrderAddress } from '../interfaces';

// Components
import Tooltip from './Tooltip.vue';
import Icon from './Icon.vue';

@Component({
  model: {
    prop: 'address',
  },
  components: {
    Tooltip,
    Icon,
  },
})
export default class AddressCard extends Vue {
  @Prop(Object) readonly address!: OrderAddress

  @Prop(String) readonly title!: string

  @Prop(String) readonly editView!: string

  @Prop(String) readonly source!: string

  @Prop(String) readonly infoTooltipText!: string

  @Prop(Boolean) readonly addressOnly!: boolean
}
