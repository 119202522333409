










































































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

// Components
import AddressCard from './AddressCard.vue';
import CreditCardIcon from './CreditCardIcon.vue';

// Interfaces
import { Order, OrderAddress } from '../interfaces';

@Component({
  components: {
    AddressCard,
    CreditCardIcon,
  },
  computed: {
    ...mapState([
      'currentOrder',
      'isCustomerCheckingOut',
    ]),

    ...mapGetters([
      'orderHasService',
      'shippingAddress',
      'billingAddress',
      'monitoringAddress',
      'isMasPrepopulated',
    ]),
  },
})
export default class PersonalInfoTable extends Vue {
  currentOrder!: Order

  shippingAddress!: OrderAddress

  billingAddress!: OrderAddress

  monitoringAddress!: OrderAddress

  isCustomerCheckingOut!: boolean

  orderHasService!: boolean

  isMasPrepopulated!: boolean;

  get billingShippingTitle() {
    if (this.isCustomerCheckingOut) {
      return this.isMasPrepopulated ? 'Billing/Installation Address' : 'Billing/Shipping Address';
    }
    return 'Shipping Address';
  }

  get emailPhone() {
    return this.isMasPrepopulated ? 'Email/Phone' : 'Email';
  }

  get billingSource() {
    return this.billingAddress ? '' : 'Shipping';
  }

  get monitoringSource() {
    return this.monitoringAddress ? '' : 'Shipping';
  }

  get paymentRoute() {
    return this.isCustomerCheckingOut ? '' : 'payment';
  }

  get monitoringRoute() {
    return this.isMasPrepopulated ? undefined : 'monitoring';
  }

  get shippingRoute() {
    return this.isMasPrepopulated ? undefined : 'shipping';
  }

  get billingAddressTooltipText() {
    return this.isCustomerCheckingOut ? 'If billing address is different from shipping address,\n' +
      '        please call (833) 327-4657 to complete your order.\n' +
      '        Currently, editing of the billing address for online orders is not available.' : '';
  }
}
