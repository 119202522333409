







































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// Utils
import SectionCard from '@/components/SectionCard.vue';
import { mixpanelTrackStep } from '../utils/mixpanel';

// Interfaces
import { Order } from '../interfaces';


@Component({
  components: {SectionCard},
  computed: {
    ...mapGetters(['isMasPrepopulated']),
    ...mapState(['currentOrder', 'isSalesRepCheckingOut', 'isAuthorizedPartnerCheckingOut']),
  },
})
export default class CompleteView extends Vue {
  currentOrder!: Order;
  isSalesRepCheckingOut!: boolean;
  isAuthorizedPartnerCheckingOut!: boolean;
  isMasPrepopulated!: boolean;

  editCallbackUrl = sessionStorage.getItem('editCallbackUrl');

  mounted() {
    mixpanelTrackStep('Completed', this.currentOrder);
  }

  get showCPQData(): boolean {
    return (
      this.isSalesRepCheckingOut &&
      !!this.currentOrder.cpqQuoteId
    );
  }

  get orderNumber() {
    return this.currentOrder.netsuiteQuoteId ?? this.currentOrder.id;
  }

  get netsuiteSalesOrderId() {
    return this.currentOrder.netsuiteId ?? 'pending';
  }

  get congratulationsText() {
    if (!this.isMasPrepopulated) {
      return this.isAuthorizedPartnerCheckingOut || this.isSalesRepCheckingOut
        ? 'Congratulations! If the customer has electronically signed the agreement, the order is complete.'
        : 'Your Brinks Home™ order is complete!';
    }
    return 'Your Brinks Home order is complete!';
  }
}

