















































// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// APIs
import CheckoutApi from '../apis/checkout-api';

// Interfaces
import { Order, OrderFinancingStatus, Step } from '../interfaces';

// Components
import Icon from './Icon.vue';

@Component({
  components: {
    Icon,
  },
  computed: {
    ...mapGetters(['currentStep', 'isMasPrepopulated']),
    ...mapState([
      'currentOrder',
      'isCustomerCheckingOut',
      'isSubmitting',
      'isAuthorizedPartnerCheckingOut',
      'isSalesRepCheckingOut',
    ]),
  },
})
export default class StepActions extends Vue {
  @Prop({ default: 'Save And Continue' }) continueButtonTitle!: string;

  @Prop({ default: true }) continueButtonEnabled!: boolean;

  @Prop()
  preSubmit!: Function;

  @Prop()
  showSpinner!: boolean;

  currentOrder!: Order;

  editCallbackUrl = sessionStorage.getItem('editCallbackUrl');

  isCustomerCheckingOut!: boolean;

  isSubmitting!: boolean;

  isSalesRepCheckingOut!: boolean;

  isAuthorizedPartnerCheckingOut!: boolean;

  isMasPrepopulated!: boolean;

  currentStep!: Step;

  get showEditButton(): boolean {
    return !this.isCustomerCheckingOut && !!this.editCallbackUrl && (
      !this.currentOrder.financingStatus ||
      this.currentOrder.financingStatus === OrderFinancingStatus.DECLINED
    );
  }

  get preSubmitReq(): boolean {
    return typeof this.preSubmit === 'function';
  }

  async editOrder() {
    if (this.editCallbackUrl) {
      await new CheckoutApi().resetOrderStep(this.currentOrder.id!, this.currentOrder.authToken!);
      window.location.href = this.editCallbackUrl;
    }
  }
}
