




























import { toNumber } from 'lodash-es';

// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';

// Components
import AnimatedNumber from './AnimatedNumber.vue';

@Component({
  components: {
    AnimatedNumber,
  },
})
export default class Price extends Vue {
  @Prop({ required: true }) value!: any

  @Prop() subtext!: string

  @Prop() subtextPrimary!: string

  @Prop({ default: '$' }) currencySymbol!: string

  @Prop() variantClasses!: string

  @Prop() showZeroCents!: boolean

  @Prop() animate!: boolean

  @Prop() strike!: boolean

  @Prop() displayHTML!: boolean

  get numberValue() {
    return toNumber(this.value);
  }

  get dollarValue() {
    return Math.floor(this.numberValue);
  }

  get centsValue() {
    const parsedCents = this.numberValue.toFixed(2);
    return parsedCents.toString().split('.')[1];
  }

  get decimals() {
    if (this.showZeroCents || this.value % 1 !== 0) {
      return 2;
    }
    return 0;
  }
}
