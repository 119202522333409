import { extend } from 'vee-validate';
import { required, email, min, max, alpha_num } from 'vee-validate/dist/rules';

import Payment from 'payment';
import { messages } from 'vee-validate/dist/locale/en.json';

// Utils
import { expIn90Days, validateCardLowEnv } from '../utils/credit-card';

// Config
import config from '../config';

// No message specified.
extend('email', { ...email, message: "Enter a valid email" });
extend('min', { ...min, message: messages.min });
extend('max', { ...max, message: messages.max });
extend('alphaNum', { ...alpha_num, message: messages.alpha_num });

const fieldNames: Record<string, string> = {
  first_name: 'first name',
  last_name: 'last name',
  address1: 'address',
  secondary_first_name: 'first name',
  secondary_last_name: 'last name',
  secondary_phone: 'phone',
  credit_card_number: 'card number',
  cardholder_name: 'name',
  expiration: 'expiration date',
  cvv: 'security code',
  zip: 'ZIP code',
  financing_reference_number: 'system project id',
}

// Override the default message.
extend('required', {
  ...required,
  message: (field: string) => `Enter a valid ${fieldNames[field] || field}`,
});

extend('customerName', {
  validate: value => /^[a-zA-Z ]{0,20}$/.test(value),
  message: 'Enter a valid name',
});

extend('phone', {
  validate: value => /[2-9]\d{2}(?!555)[2-9]\d{6}/.test(value.replace(/\D/g, '')),
  message: 'Enter a valid phone number',
});

extend('creditCardNumber', {
  validate:
    config.currentEnvironment !== 'production'
      ? value => validateCardLowEnv(value)
      : value => Payment.fns.validateCardNumber(value),
  message: 'Enter a valid card number',
});

extend('creditCardExpiry', {
  validate: value => {
    if (!Payment.fns.validateCardExpiry(value)) {
      return Payment.fns.validateCardExpiry(value);
    }
    return !expIn90Days(value);
  },
  message: 'Expiration date is not valid or expires in less than 3 months',
});

// Extra validations
extend('creditCardCvc', {
  params: ['creditCardType'],
  validate: (value, { creditCardType }: any) => Payment.fns.validateCardCVC(value, creditCardType),
  message: 'Enter a valid security code',
});

extend('ssn', {
  validate: value => /^(?!(0000))\d{4}$/.test(value),
  message: 'Last 4 SSN can only contain 4 numbers',
});

extend('secondaryPhone', {
  params: ['primaryPhone'],
  validate: (value, { primaryPhone }: any) => value !== primaryPhone,
  message: 'Secondary Phone Number needs to be different from Primary Phone Number',
});

extend('zipCode', {
  params: ['isZipCodeValid'],
  validate: (value, { isZipCodeValid }: any) => isZipCodeValid === 'true',
  message: 'Enter a valid ZIP code',
});
extend('codeword', {
  validate: value => /^[a-zA-Z0-9]*$/.test(value),
  message: 'Enter a valid code word. Click the tool tip to learn more.',
});

extend('codewordConfirmation', {
  params: ['target'],
  validate: (value, { target } : any) => value === target,
  message: 'The two entries do not match, please try again.',
});
