








































































































































































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

// Utils
import pluralize from 'pluralize';

// Components
import CartSummaryItem from './CartSummaryItem.vue';
import AnimatedNumber from './AnimatedNumber.vue';
import Tooltip from './Tooltip.vue';
import Icon from './Icon.vue';

// Interfaces
import { Order, OrderItem } from '../interfaces';

@Component({
  components: {
    AnimatedNumber,
    CartSummaryItem,
    Tooltip,
    Icon,
  },
  computed: {
    ...mapState([
      'currentOrder',
      'isSalesRepCheckingOut',
      'isAuthorizedPartnerCheckingOut',
    ]),
    ...mapGetters([
      'orderHasSystem',
      'services',
      'servicesTotal',
      'allInMonthlyPrice',
      'monitoringOnlyOrder',
      'isMasPrepopulated',
      'todayTotal',
      'isDIYOrder',
    ]),
  },
})
export default class CartSummary extends Vue {
  orderHasSystem!: Function

  services!: OrderItem[]

  monitoringOnlyOrder!: boolean

  currentOrder!: Order

  isSalesRepCheckingOut!: boolean;

  isAuthorizedPartnerCheckingOut!: boolean;

  isMasPrepopulated!: boolean;

  isDIYOrder!: boolean;

  get title() {
    return this.orderHasSystem ? 'Your System' : 'Your Order';
  }

  get products() {
    return this.currentOrder.itemAddons ?? [];
  }

  get hasDiscounts() {
    const discounts = this.currentOrder.discounts ?? [];
    return discounts.length > 0;
  }

  get subtotalWithDiscounts() {
    return this.currentOrder.subtotal - this.currentOrder.discountTotal;
  }

  get showShippingLine() {
    return !this.currentOrder.isSubsidized && !this.monitoringOnlyOrder && !this.isMasPrepopulated;
  }

  get show2YearWarranty() {
    return (!this.isAuthorizedPartnerCheckingOut ? (!this.isMasPrepopulated) : false) && (!this.isDIYOrder);
  }

  get showECommDIYServices() {
    return !this.currentOrder.isSubsidized && this.isDIYOrder;
  }

  get showServices() {
    return !this.currentOrder.isSubsidized && !this.isDIYOrder;
  }

  pluralizeName(name: string, quantity: number) {
    return pluralize(name, quantity);
  }
}
