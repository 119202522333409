


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { maskInput, masks, MaskName } from '../../utils/masks';
import ForCurrency from '../../utils/for-currency';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TextField extends Vue {
  @Prop(String) readonly label!: string;

  @Prop(String) readonly labelSupplement!: string;

  @Prop({ type: [String, Number] }) readonly value!: string | number;

  @Prop({ default: 'text' }) readonly type!: string;

  @Prop({ default: 'off' }) readonly autocorrect!: string;

  @Prop({ default: 'on' }) readonly autocomplete!: string;

  @Prop({ default: '.*\\w+.*' }) readonly pattern!: string;

  @Prop(String) readonly mask!: MaskName;

  // Pipe-separated string of desired validation rules
  @Prop({ default: '' }) readonly validationRules!: string;

  @Prop({ default: 'eager' }) readonly validationMode!: string;

  @Prop(Boolean) readonly required!: boolean;

  inputEl?: HTMLInputElement;

  lastBlurredValue = this.value;

  forCurrency = ForCurrency;

  @Watch('value', { immediate: true, deep: true })
  format() {
    if (this.mask && this.inputEl) {
      const start = this.inputEl.selectionStart;
      const end = this.inputEl.selectionEnd;
      maskInput(this.inputEl, masks[this.mask]);
      this.inputEl.setSelectionRange(start || 0, end || 0);
    }
  }

  mounted() {
    this.inputEl = this.$refs.input as HTMLInputElement;
    this.format();
  }

  updateValue(value: string) {
    this.$emit('input', value);
  }

  blur() {
    // have to recreate change event here because Inputmask on text fields prevents change from firing normally
    this.$emit('blur', this.value);

    if (this.lastBlurredValue !== this.value) {
      this.$emit('change', this.value);
    }

    this.lastBlurredValue = this.value;
  }

  get name() {
    return this.$attrs.name || this.label;
  }

  get validators(): string {
    if (!this.required) {
      return this.validationRules;
    }

    const splitValidators = (this.validationRules && this.validationRules.split('|')) || [];
    if (!splitValidators.includes('required')) {
      splitValidators.unshift('required'); // frontload
    }

    return splitValidators.join('|');
  }

  scrubLeadingZeroAndTrailingDecimal($event: any) {
    const { value } = $event.target;
    if (parseFloat(value) > 0 && (value.charAt(0) === '0' || value.charAt(0) === '-')) {
      this.updateValue(value.substring(1));
    }
    if (value != null && value.indexOf('.') > -1 && value.split('.')[1].length > 2) {
      this.updateValue(value.substring(0, value.length - 1));
    }
  }
}
