







































































import dropin from 'braintree-web-drop-in';
import { uniqueId } from 'lodash-es';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

import Icon from '../Icon.vue';
import Tooltip from '../Tooltip.vue';
import TextField from '../form/TextField.vue';
import SectionCard from '../SectionCard.vue';

import CheckoutApi from '../../apis/checkout-api';
import { Order } from '../../interfaces';

@Component({
  components: {
    Icon,
    TextField,
    Tooltip,
    SectionCard,
  },
  computed: {
    ...mapState(['currentOrder']),
    ...mapGetters(['orderRequiresMonitoring']),
  },
})
export default class NewCreditCard extends Vue {
  checkoutApi = new CheckoutApi();
  containerId = uniqueId('braintree_');
  currentOrder!: Order;
  braintreeDropin: any = null;
  braintreeDropinHeight = 0;
  orderRequiresMonitoring!: boolean;
  dropinContainer: HTMLElement | undefined | null = null;

  @Prop({ default: false }) showSplitButton!: boolean;
  @Prop({ default: false }) showRemoveButton!: boolean;
  @Prop({ default: false }) isPaymentSplit!: boolean;
  @Prop({ default: false }) isProcessing!: boolean;
  @Prop({ default: false }) useForMonitoring!: boolean;
  @Prop({ default: 0 }) splitAmount!: number;
  @Prop({ default: true }) disableSplitAmount!: number;

  async mounted() {
    await this.startBraintree();
  }

  async startBraintree() {
    this.dropinContainer = document.getElementById(this.containerId);
    if (this.dropinContainer) {
      this.cleanupBraintreeDropin();
    }

    try {
      const clientToken = await this.checkoutApi.fetchPaymentToken(this.currentOrder);

      dropin.create(
        {
          authorization: clientToken,
          container: this.dropinContainer!,
          card: {
            overrides: {
              fields: {
                number: {
                  // When selector is missing, TypeScript complains even though it is deprecated.
                  selector: '',
                  maxCardLength: 16,
                },
              },
            },
            cardholderName: {
              required: true,
            },
          },
        },
        (createErr: object | null, dropinInstance?: dropin.Dropin) => {
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.
            console.error('Could not start braintree', createErr);
            return;
          }

          this.braintreeDropin = dropinInstance;
          this.$emit('dropinCreate', dropinInstance);
          this.braintreeDropinHeight = (this.$refs.braintreeDropin as HTMLElement).clientHeight;
        },
      );
    } catch (e) {
      console.error("Could't fetch braintree token", e);
    }
  }

  cleanupBraintreeDropin() {
    if (!this.braintreeDropin) {
      return;
    }

    this.braintreeDropin.teardown();
    this.braintreeDropin = null;
  }

  handleSplitPayment() {
    this.$emit('splitPayment');
  }

  handleRemove() {
    this.$emit('remove');
  }

  handleMonitoringChange() {
    this.$emit('monitoringChange');
  }

  handleSplitAmountChange(value?: string) {
    this.$emit('splitAmountChange', value);
  }

  handleSplitAmountChangeBlur(value?: string) {
    if (!value) {
      this.$emit('splitAmountChange', 0);
    }
  }
}
