






































// Vue
import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';

// APIs
import CheckoutApi from '../apis/checkout-api';
import { payloadStepCompletedReview } from '../apis/payloads/checkout-api';

// Components
import SectionCard from '../components/SectionCard.vue';
import PersonalInfoTable from '../components/PersonalInfoTable.vue';
import LineItemsTable from '../components/LineItemsTable.vue';
import { AgreementModal, ConfirmContactRecModal } from '../components/modals';
import StepActions from '../components/StepActions.vue';
import Alert from '../components/Alert.vue';

// Interfaces
import { Order, OrderStep, ContactRecipient, OrderAddress } from '../interfaces';

// Utils
import { pushGAFunnel } from '../utils/gtm';
import { mixpanelTrackStep } from '../utils/mixpanel';

@Component({
  components: {
    SectionCard,
    PersonalInfoTable,
    LineItemsTable,
    AgreementModal,
    StepActions,
    Alert,
    ConfirmContactRecModal,
  },
  computed: {
    ...mapState([
      'currentOrder',
      'isCustomerCheckingOut',
      'isSalesRepCheckingOut',
      'isSubmitting',
      'isOrderAlreadyProcessed',
      'contractRecipientEmail',
      'contractRecipientPhone',
      'acceptedSmsTerms',
      'isAuthorizedPartnerCheckingOut',
      'errorMessage',
    ]),
    ...mapGetters([
      'isFeatureFlagActive',
      'isMasPrepopulated',
      'shippingAddress',
    ]),
  },
  methods: {
    ...mapMutations([
      'orderSubmitting',
      'orderSubmittingComplete',
      'updateIsOrderAlreadyProcessed',
      'updateCurrentOrder',
      'blockNextSteps',
    ]),
  },
})
export default class Review extends Vue {
  checkoutApi = new CheckoutApi();
  currentOrder!: Order;
  isCustomerCheckingOut!: boolean;
  isSalesRepCheckingOut!: boolean;
  isAuthorizedPartnerCheckingOut!: boolean;
  isMasPrepopulated!: boolean;
  isSubmitting!: boolean;
  isOrderAlreadyProcessed!: boolean;
  isFeatureFlagActive!: Function;
  orderSubmitting!: Function;
  orderSubmittingComplete!: Function;
  submitRequestStartTime!: number;
  submitRequestCurrentTime!: number;
  submitRequestElapsedTime = 0;
  orderSubmittingTimer!: number;
  updateIsOrderAlreadyProcessed!: Function;
  updateCurrentOrder!: Function;
  errorMessage?: string;
  contractRecipientEmail!: string;
  contractRecipientPhone!: string;
  acceptedSmsTerms!: boolean;
  signNow = false;
  blockNextSteps!: Function;
  shippingAddress!: OrderAddress;

  updateSubmitRequestElapsedTime(val: number) {
    this.submitRequestElapsedTime = val;
  }

  updateSignNow(val: boolean) {
    this.signNow = val;
  }

  preSubmit() {
    ($((this.$refs.confirmContactRecModal as Vue).$el) as any).modal({
      backdrop: 'static',
      keyboard: false,
    });
  }

  dismissConfirmContactRecModal() {
    ($((this.$refs.confirmContactRecModal as Vue).$el) as any).modal('hide');
  }

  async startTrackingSubmitRequest() {
    this.submitRequestStartTime = (new Date()).getTime();
    this.submitRequestCurrentTime = 0;

    this.orderSubmittingTimer = setInterval(() => {
      this.submitRequestCurrentTime = (new Date()).getTime();
      this.updateSubmitRequestElapsedTime(this.submitRequestCurrentTime - this.submitRequestStartTime);
    }, 1000);
  }

  async stopTrackingSubmitRequest() {
    this.submitRequestStartTime = 0;
    this.submitRequestCurrentTime = 0;

    clearInterval(this.orderSubmittingTimer);
  }

  async submit() {
    if (!this.isOrderAlreadyProcessed) {
      this.orderSubmitting();
      const contractRecipient: ContactRecipient = {
        email: this.contractRecipientEmail,
        phone: this.contractRecipientPhone,
        acceptedSmsTerms: this.acceptedSmsTerms,
      };
      try {
        const payload = payloadStepCompletedReview(this.currentOrder, contractRecipient)
        this.startTrackingSubmitRequest();
        const response = await this.checkoutApi.completeStep(
          this.currentOrder,
          OrderStep.REVIEW,
          payload,
        );
        this.updateCurrentOrder({
          step: response.step,
          netsuiteId: response.netsuiteId,
          contractUrl: response.contractUrl,
        });

        this.updateIsOrderAlreadyProcessed(true);
        this.orderSubmittingComplete(false);
        pushGAFunnel(6, this.currentOrder);

        if (!this.isCustomerCheckingOut) {
          ($((this.$refs.agreementModal as Vue).$el) as any).modal({
            backdrop: 'static',
            keyboard: false,
          });
        } else if (this.currentOrder.contractUrl) {
          window.location.href = this.currentOrder.contractUrl;
        } else {
          this.$router.push(OrderStep.COMPLETE);
        }
      } catch (error) {
        const message = error.response?.data?.error?.message ?? ""
        const errorMessage = message.split("UIError:")[1] ??
          `There was a problem creating your order. Please try again.`;
        this.orderSubmittingComplete(errorMessage);

        if(errorMessage.includes("Credit card")) {
          this.blockNextSteps(OrderStep.PAYMENT);
          this.$router.push(OrderStep.PAYMENT);
        }
      } finally {
        this.stopTrackingSubmitRequest();
      }
    }
  }

  get placeOrderText() {
    let val: string;
    if (this.isSubmitting) {
      val = 'Placing Order';

      if (this.submitRequestElapsedTime > 60000) {
        val = "Placing Order (it must be slow today)...";
      } else if (this.submitRequestElapsedTime > 49000) {
        val = 'Placing Order (almost done)...';
      } else if (this.submitRequestElapsedTime > 42000) {
        val = 'Placing Order (nearing the end now)...';
      } else if (this.submitRequestElapsedTime > 35000) {
        val = 'Placing Order (just a little more to go)...';
      } else if (this.submitRequestElapsedTime > 28000) {
        val = "Placing Order (it's still happening)...";
      } else if (this.submitRequestElapsedTime > 21000) {
        val = 'Placing Order, thank you for your patience...';
      } else if (this.submitRequestElapsedTime > 14000) {
        val = 'Placing Order, please continue to wait...';
      } else if (this.submitRequestElapsedTime > 7000) {
        val = 'Placing Order, please wait...';
      }
    } else if (this.isCustomerCheckingOut) {
      val = 'Place Order';
    } else {
      val = 'Next';
    }
    return val;
  }

  get showPresubmitForm() {
    return this.isSalesRepCheckingOut || this.isAuthorizedPartnerCheckingOut
  }

  get reviewTitle() {
    return this.isMasPrepopulated ? 'Review & Submit' : 'Review & Complete';
  }

  mounted() {
    this.orderSubmittingComplete();
    if (!this.currentOrder.monitoringInformation!.primaryPhone) {
      this.currentOrder.monitoringInformation!.primaryPhone = this.shippingAddress.phone!;
    }
    pushGAFunnel(5, this.currentOrder);
    mixpanelTrackStep('Review', this.currentOrder);
  }
}
