import { StepName } from '@/interfaces';

const titles = {
  shipping: 'Shipping',
  payment: 'Payment',
  monitoring: 'Monitoring',
  review: 'Review & Complete',
};

const checkoutSteps = (stepPaths: Array<StepName>) => {
  const steps = stepPaths.map((path) => ({
    path,
    unlocked: false,
    title: titles[path] || path.toLowerCase(),
  }));

  steps[0].unlocked = true;

  return steps;
};

export const monitorBeforePaymentSteps = checkoutSteps(['shipping', 'monitoring', 'payment', 'review']);
export const paymentBeforeMonitorSteps = checkoutSteps(['shipping', 'payment', 'monitoring', 'review']);
export const nonMonitoredSteps = checkoutSteps(['shipping', 'payment', 'review']);
export const nonShippingSteps = checkoutSteps(['monitoring', 'payment', 'review']);

/*
  Function to convert a list of paths into
  objects that are used by the breadcrumb navigation.
  The first path is always unlocked( user can click it)

  Example:
    checkoutSteps(['shipping', 'review'])

    =>
    [
      {
        path: 'shipping',
        unlocked: true,
        title: 'Shipping',
      },
      {
        path: 'review',
        unlocked: false,
        title: 'Review & Complete',
      }
    ]
 */
