import moment from 'moment';
import business from 'moment-business';

export const isValidActivationDate = (date: Date) => {
  const isWeekday = [1, 2, 3, 4, 5].includes(date.getUTCDay())
  const isFuture = moment(date) > business.addWeekDays(moment(), 7);
  return isWeekday && isFuture
}
export const notBeforeAfterEightOClock = (date: Date) => {
  const after8AM = date.getHours() >= 8;
  const before8PM = date.getHours() <= 20;
  return after8AM && before8PM
}
