




























// Vue
import { Vue, Component, Prop } from 'vue-property-decorator';

// Interfaces
import { OrderItem } from '@/interfaces';

// Components
import AnimatedNumber from './AnimatedNumber.vue';

@Component({
  components: {
    AnimatedNumber,
  },
})
export default class CartSummaryItem extends Vue {
  @Prop(Object) readonly item!: OrderItem

  @Prop(Boolean) readonly isProduct!: boolean

  @Prop(String) readonly description!: string

  get hasDiscount() {
    return (this.item.discountPerUnit ?? 0) > 0;
  }

  get secondLineClass() {
    return this.isProduct ? 'justify-content-between mt-1' : 'justify-content-end';
  }

  get subtotal() {
    const { price, quantity, discountPerUnit } = this.item;
    return (price - (discountPerUnit ?? 0)) * (quantity > 0 ? quantity : 1);
  }
}

